import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TimeRangeSliderModule } from 'src/app/common/calendar/time-range-slider/time-range-slider.module';
import { DefaultImageModule } from 'src/app/common/default-image/default-image.module';
import { TableModule } from 'src/app/common/table/table.module';
import { AmbientAirConcentrationsComponent } from './ambient-air-concentrations.component';

@NgModule({
  declarations: [AmbientAirConcentrationsComponent],
  imports: [
    CommonModule,
    TableModule,
    TimeRangeSliderModule,
    DefaultImageModule
  ]
})
export class AmbientAirConcentrationsModule { }
