import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import * as dateFnsFp from 'date-fns/fp';
import * as _ from 'lodash';


@Component({
  selector: 'app-sat-and-radar',
  templateUrl: './sat-and-radar.component.html'
})
export class SatAndRadarComponent implements OnInit, OnChanges, OnDestroy {

  header = "Satellite+Radar";
  timerId: any = null;
  delayThreshold = 5;
  imageUrl: string = "";
  imageArr: string[] = [];
  index: number = 0;
  sec: number = -1;

  @Input()
  date = new Date();

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes?.date.isFirstChange()) {
      this.update();
    }
  }

  update() {
    let min: number = -1;

    this.imageArr = [...Array(18).keys()].map(_index => {
      ++min;
      return _.flow(
        dateFnsFp.subMinutes(min * 10),
        dateFnsFp.format("yyyyMMddHHmm")
      )(this.date);
    }).reverse();

    this.updateInterval();
  }

  updateInterval() {
    clearInterval(this.timerId);
    this.index = 0;

    let temp = 0;

    this.timerId = setInterval(() => {
      // this.imageUrl = this.imageArr[this.index];
      this.imageUrl = "https://www.weather.go.kr/w/cgi-bin/rdr_new/nph-rdr_sat_lgt_img_v3?tm=" + this.imageArr[this.index] + "&sa%5B%E2%80%A6%5DHC&size=640&zoom_level=0&zoom_x=0000000&zoom_y=0000000&fog=0";
      this.index = ++this.index % this.imageArr.length;

      const dataLength = this.imageArr.length - 1;
      if (this.index >= dataLength || temp > 0) {
        this.index = dataLength;
        temp++;

        if (temp >= this.delayThreshold) {
          this.index = 0;
          temp = 0;
        }
      }
    }, 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.timerId);
  }

}
