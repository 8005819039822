import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  private _store: { [key: string]: any } = {
    members_permitted: [
      { email: "zhang@hotmail.com", name: "Zhuang Hong", nationality: "china", charge: "chief engineer", organization: "Xiamen Envirionmental Monitoring Central Station", telephone: "123-7890-5683", address: "Xiamen Envirionmental Monitoring Central Station", usertype: "sub_wg-ii", role: "member" },
      { email: "sh@hotmail.com", name: "Suzuki Honda", nationality: "japan", charge: "Director", organization: "Ministry of Envirionment", telephone: "123-7890-5683", address: "Tokyo Japan", usertype: "sub_wg-I", role: "member" },
      { email: "bbb@daum.net", name: "Kim Min-soo", nationality: "korea", charge: "the Secretariat", organization: "National Institue of Environmental Research", telephone: "123-7890-5683", address: "Kyungseo-dong, Seo-gu, Incheon 404-170, Republic of Korea", usertype: "secretariat", role: "staff" },
      { email: "jamek@hotmail.net", name: "Jane Smith Kim", nationality: "korea", charge: "Director", organization: "Ministry of Environment", telephone: "123-7890-5683", address: "-", usertype: "working-group", role: "staff" },
      { email: "zz@msn.com", name: "Han Min-na", nationality: "korea", charge: "Director", organization: "Ministry of Environment", telephone: "123-7890-5683", address: "-", usertype: "working-group", role: "staff" }
    ],
    members_waiting: [
      { email: "ex@asdf.com", name: "Shen Long", nationality: "china", charge: "chief engineer", organization: "Xiamen Envirionmental Monitoring Central Station", telephone: "123-7890-5683", address: "Xiamen Envirionmental Monitoring Central Station", usertype: "sub_wg-ii", role: "member" },
      { email: "edf@fff.com", name: "Kim yeong-hee", nationality: "korea", charge: "Director", organization: "-", telephone: "123-7890-5683", address: "Seoul, Korea", usertype: "sub_wg-I", role: "member" }
    ]
  };
  private _events: { [key: string]: EventEmitter<any> } = {};

  /* private traceStore(key: string): any {
    const ks = key.split(".");
    return key.split(".").reduce((p, r) => p? p[r]: r, this._store);
  } */

  private event<T>(key: string): EventEmitter<T> {
    if (this._events[key] == null)
      this._events[key] = new EventEmitter();

    return this._events[key];
  }

  subscribe<T>(key: string, handler: (v: T) => void, initialValue?: T) {
    if (initialValue)
      this._store[key] = initialValue;

    const event = this.event(key);
    event.subscribe(handler);
    event.emit(this._store[key]);
  }

  unsubscribe(key: string) {
    if (this._events[key] == null || this._events[key].observers.length <= 0)
      return;

    this._events[key].unsubscribe();
    delete this._events[key];
  }

  publish<T>(key: string, value: any, handler?: (partialStore: T, response: any) => T) {
    const copy = this._store[key] == null ? {} : JSON.parse(JSON.stringify(this._store[key]));
    this._store[key] = handler ? handler(copy, value) : value;
    this.event(key).emit(this._store[key]);
  }

  get(key: string): any {
    return this._store[key];
  }

  fileDummies = [
    { name: "dummy.png", size: 111, url: "#dummy.png" },
    { name: "dummy.flac", size: 111, url: "#dummy.flac" },
    { name: "dummy.mp4", size: 111, url: "#dummy.mp4" },
    { name: "dummy.pdf", size: 111, url: "#dummy.pdf" },
    { name: "dummy.docx", size: 111, url: "#dummy.docx" },
    { name: "dummy.xlsx", size: 111, url: "#dummy.xlsx" },
    { name: "dummy.pptx", size: 111, url: "#dummy.pptx" },
    { name: "dummy.zip", size: 111, url: "#dummy.zip" },
    { name: "dummy.gb2", size: 111, url: "#dummy.gb2" }
  ]
}
