import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MembersModule } from './members/members.module';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MembersModule
  ]
})
export class AdminModule { }
