import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DateControlsModule } from 'src/app/common/calendar/date-controls/date-controls.module';
import { ImageColumnsModule } from 'src/app/common/image-columns/image-columns.module';
import { KalionKoreaComponent } from './kalion-korea.component';

@NgModule({
  declarations: [
    KalionKoreaComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    // TableModule,
    DateControlsModule,
    // DefaultImageModule
    ImageColumnsModule
  ]
})
export class KalionKoreaModule { }
