import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CopyrightModule } from 'src/app/common/copyright/copyright.module';
import { DefaultImageModule } from 'src/app/common/default-image/default-image.module';
import { CurrentModule } from './current/current.module';
import { ForecastModule } from './forecast/forecast.module';
import { WeatherAnalysisComponent } from './weather-analysis.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CopyrightModule,
    DefaultImageModule,
    CurrentModule,
    ForecastModule
  ],
  declarations: [
    WeatherAnalysisComponent
  ]
})
export class WeatherAnalysisModule { }
