import { CustomStepDefinition, LabelType, Options } from '@angular-slider/ngx-slider';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as dateFns from "date-fns";
import * as _ from 'lodash';

interface Indicies {
  start: number | (() => number)
  end: number | (() => number)
}

export interface DateRange {
  current: Date
  start: Date
  end: Date
}

@Component({
  selector: 'app-time-range-slider',
  templateUrl: './time-range-slider.component.html',
  styleUrls: ['./time-range-slider.component.css']
})
export class TimeRangeSliderComponent implements OnInit {

  @Input()
  unit: 'd' | 'h' | 'm' = 'h';

  @Input()
  intervals: number[] = [1, 3];

  @Input()
  interval: number = 1;

  @Input()
  isShownInterval = true;

  @Input()
  step2: number = 6;

  @Input()
  isHiddenSlider = false;

  /**
   * Local Date 만 사용
   */
  _currentDate = new Date();

  @Input()
  set currentDate(date: Date) {
    switch (this.unit) {
      case 'd':
        this._currentDate = dateFns.startOfMonth(date)
        break;

      case 'h':
        this._currentDate = dateFns.startOfDay(date);
        break;

      default:
        this._currentDate = dateFns.startOfHour(date);
        break;
    }
  }

  get currentDate() {
    return this._currentDate;
  }

  @Input()
  relativeMin = 0;

  @Input()
  relativeMax = 48;

  play = false;

  @Output()
  playChange = new EventEmitter<boolean>();

  @Output()
  ready = new EventEmitter<DateRange>();

  @Output()
  dateChange = new EventEmitter<DateRange>();

  @Output()
  valueChange = new EventEmitter<Date>();

  trsForm: FormGroup = this.fb.group({
    speed: [1, Validators.required],
    interval: [1, Validators.required]
  });

  private isInit = false;
  // isPlayed = false;
  private dateRange: Date[] = [];
  private timeout: any;
  private dateRangeStartIndex = 0;
  // 구간 반복시 처음 위치를 기억하기 위해 필요
  private dateRangeStartIndexCopy = 0;
  private dateRangeEndIndex = 0;

  minValue: number = 0;
  maxValue: number = 1;

  private dateInterval: DateRange = {
    current: this.currentDate,
    start: new Date(this.minValue),
    end: new Date(this.maxValue)
  }

  options: Options = {
    showTicksValues: true,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.TickValue:
          let tickFormat;
          if (this.unit == 'd') {
            tickFormat = "dd";
          }
          else if (this.unit == 'h') {
            tickFormat = "HH'h'";
          }
          else {
            tickFormat = "HH:mm";
          }

          return dateFns.format(value, tickFormat);

        default:
          let titleFormat;
          if (this.unit == 'd') {
            titleFormat = "yyyy.MM.dd '(KST)'";
          }
          else if (this.unit == 'h') {
            titleFormat = "yyyy.MM.dd HH'h' '(KST)'";
          }
          else {
            titleFormat = "yyyy.MM.dd HH:mm '(KST)'";
          }

          return dateFns.format(value, titleFormat);
      }
    }
  };

  speedControls = [1, 2].map(speed => ({
    value: speed,
    name: speed + 'x',
    checked: speed == 1
  }));

  intervalControls: any = [];

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.intervalFormValue = this.interval;

    this.intervalControls = this.intervals.map(interval => ({
      value: interval,
      name: interval + this.unit,
      checked: interval == this.intervalFormValue
    }));

    this.changePlayStatus(false);
    this.resetSlider(this.currentDate, this.intervalFormValue, this.indiciesForReset());

    this.trsForm.get("speed")?.valueChanges.subscribe(_speed => this.changePlayStatus(this.play));

    this.trsForm.get("interval")?.valueChanges.subscribe(interval => {
      this.changePlayStatus(false);
      this.resetSlider(this.currentDate, interval, this.indiciesForReset());
    });

    /* this.trsForm.get("play")?.valueChanges.subscribe(play => this.playStatusChange.emit({
      isPlayed: play,
      range: {
        start: this.dateRange[0],
        end: this.dateRange[this.dateRange.length - 1]
      }
    })); */
  }

  private createDateRange(date: Date, interval: number): Date[] {

    let dateAdder: any;
    if (this.unit == 'd') {
      dateAdder = dateFns.addDays;
    }
    else if (this.unit == 'h') {
      dateAdder = dateFns.addHours;
    }
    else {
      dateAdder = dateFns.addMinutes;
    }

    let minDate = dateAdder(date, this.relativeMin);
    const maxDate = dateAdder(date, this.relativeMax);

    const range = [];

    while (dateFns.isBefore(minDate, maxDate)) {
      range.push(minDate);

      minDate = dateAdder(minDate, interval);
    }

    return range;
  }

  private indiciesForReset(): Indicies {
    return {
      start: 0,
      end: () => this.dateRange.length - 1
    }
  }

  private currentIndicies(): Indicies {
    return {
      start: this.dateRangeStartIndex,
      end: this.dateRangeEndIndex
    }
  }

  private applyDateRangeToOptions() {
    const newOptions = _.cloneDeep(this.options);
    newOptions.tickStep = this.step2 / this.intervalFormValue;
    newOptions.stepsArray = this.dateRange.map((date: Date) => {
      const step: CustomStepDefinition = { value: date.getTime() };

      let isShownLegend;
      if (this.unit == 'd') {
        isShownLegend = date.getDate() == 1;
      }
      else {
        date.getHours() == 0;
      }

      if (isShownLegend) {
        step.legend = dateFns.format(date, "MM/dd");
      }

      return step;
    });

    this.options = newOptions;
  }

  private updateIndicesAndValues({ start, end }: Indicies) {
    const startIndex = start instanceof Function ? start() : start;
    const endIndex = end instanceof Function ? end() : end;

    this.changeMinValue(startIndex);
    this.changeMaxValue(endIndex);

    /* const startDate = this.utc ? this.forceGMT2UTC(this.minValue) : new Date(this.minValue);
    const endDate = this.utc ? this.forceGMT2UTC(this.maxValue) : new Date(this.maxValue); */
    const startDate = new Date(this.minValue);
    const endDate = new Date(this.maxValue);

    this.dateInterval = {
      current: this.currentDate,
      start: startDate,
      end: endDate
    }
  }

  timeChangeAmounts(reverse?: boolean) {
    const vs = ["1D", "2D", "7D"];
    return reverse ? vs.map(v => "-" + v).reverse() : vs.map(v => "+" + v);
  }

  /* private forceGMT2UTC(timestamp: number) {
    const d = new Date(timestamp);
    return new Date(timestamp - (d.getTimezoneOffset() * 60000));
  }
 */
  private dispatchForValueChanged() {
    this.updateIndicesAndValues(this.currentIndicies());
    this.valueChange.emit(this.dateInterval.start);
  }

  private dispatchForDateChanged() {
    this.updateIndicesAndValues(this.currentIndicies());
    this.dateChange.emit(this.dateInterval);
  }

  changeDate(date: Date) {
    if (this.currentDate == date)
      return;

    this.currentDate = date;
    this.resetSlider(this.currentDate, this.intervalFormValue, this.currentIndicies());

    if (!this.isInit) {
      this.isInit = true;
      this.ready.emit(this.dateInterval);
      return;
    }

    /* this.updateIndicesAndValues(this.currentIndicies());
    this.dateChange.emit(this.dateInterval); */
    this.dispatchForDateChanged();
  }

  changeSliderValue(e: any) {
    this.dateRangeStartIndex = this.dateRange.findIndex(d => d.getTime() == e.value);
    this.dateRangeStartIndexCopy = this.dateRangeStartIndex;
    this.dateRangeEndIndex = this.dateRange.findIndex(d => d.getTime() == e.highValue);

    this.dispatchForValueChanged();
  }

  private changeMinValue(index: number) {
    this.dateRangeStartIndex = index;
    this.minValue = this.dateRange[index].getTime();
  }

  private changeMaxValue(index: number) {
    this.dateRangeEndIndex = index;
    this.maxValue = this.dateRange[index].getTime();
  }

  private resetSlider(date: Date, interval: number, indicies: Indicies) {
    this.currentDate = date;
    this.dateRange = this.createDateRange(date, interval);
    this.applyDateRangeToOptions();
    this.updateIndicesAndValues(indicies);
  }

  // play or pause
  private changePlayStatus(isPlayed: boolean) {
    if (this.play !== isPlayed) {
      this.playChange.emit(isPlayed);
    }

    this.play = isPlayed;

    if (this.timeout != null) {
      clearInterval(this.timeout);
    }

    /* if (!this.isPlayed)
      return; */
    if (!this.play)
      return;

    this.timeout = setInterval(() => {
      let nextStartIndex = this.dateRangeStartIndex + 1;
      this.dateRangeStartIndex = nextStartIndex <= this.dateRangeEndIndex ? nextStartIndex : this.dateRangeStartIndexCopy;

      this.dispatchForValueChanged();
    }, 1000 / this.speed);
  }

  private changeHours(action: string) {
    // const amount = parseInt(action.substr(0, 2), 10);
    const amount = parseInt(action, 10);
    const variation = (amount / this.intervalFormValue);

    let newIndex = this.dateRangeStartIndex + variation;
    const isUnderflow = newIndex < 0;
    const isOverflow = newIndex > this.dateRangeEndIndex;

    /* if (isUnderflow || isOverflow) {
      if (isOverflow) {
        newIndex = variation;
      }
      else {
        newIndex = this.dateRangeEndIndex + variation;

        // interval이 1일 때, 마지막 index가 한 시간 모자르기 때문에 +1 보정
        if (this.intervalFormValue == 1 && this.dateRangeEndIndex == this.dateRange.length - 1) {
          newIndex++;
        }
      }
    } */

    if (isUnderflow) {
      newIndex = this.dateRangeEndIndex + amount + 1;
    }
    else if (isOverflow) {
      newIndex = this.dateRangeStartIndex + variation - 1;

      if (newIndex >= this.dateRange.length - 1) {
        newIndex = 0;
      }
    }

    this.dateRangeStartIndex = newIndex;
    this.dateRangeStartIndexCopy = newIndex;
    this.updateIndicesAndValues(this.currentIndicies());
  }

  clickPlayControls(action: string) {
    if (action == "play" || action == "pause") {
      this.changePlayStatus(action === "play");
    }
    else {
      this.changePlayStatus(false);

      switch (action) {
        case "backward":
          this.changeMinValue(0);
          break;
        case "forward":
          this.changeMinValue(this.dateRangeEndIndex - 1);
          break;
        case "reset":
          this.updateIndicesAndValues(this.indiciesForReset());
          break;
        default:
          this.changeHours(action);
          break;
      }

      this.dispatchForValueChanged();
    }
  }

  get isAvailable() {
    return this.dateRangeStartIndexCopy !== this.dateRangeEndIndex;
  }

  currentIntervalHour(isPositive: boolean) {
    return (isPositive ? "+" : "-") + this.intervalFormValue + this.unit;
  }

  get intervalFormValue(): number {
    // return parseInt(this.trsForm.get("interval")?.value, 10);
    return this.trsForm.get("interval")?.value;
  }

  set intervalFormValue(value: number) {
    if (this.intervalFormValue == value)
      return;

    // this.trsForm.get("interval")?.patchValue(value.toString());
    this.trsForm.get("interval")?.patchValue(value);
  }

  get speed(): number {
    // return parseInt(this.trsForm.get("speed")?.value, 10);
    return this.trsForm.get("speed")?.value;
  }

  set speed(value: number) {
    if (this.speed == value)
      return;

    // this.trsForm.get("speed")?.patchValue(value.toString());
    this.trsForm.get("speed")?.patchValue(value);
  }

  /* get isPlayed(): boolean {
    return this.trsForm.get("play")?.value;
  }

  set isPlayed(value: boolean) {
    if (this.isPlayed == value)
      return;

    this.trsForm.get("play")?.patchValue(value);
  } */
}
