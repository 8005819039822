<nav class="navbar is-fixed-top" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
        <a class="navbar-item" [routerLink]="[ '/' ]">
            <!-- <img src="assets/images/logo.png"> -->
            <!-- <img src="https://via.placeholder.com/500x300" /> -->
        </a>

        <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasic"
            [ngClass]="{'is-active': isExpanded}" (click)="toggle()">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
        </a>
    </div>

    <div id="navbarBasic" class="navbar-menu" [ngClass]="{'is-active': isExpanded}">
        <div class="navbar-start">
            <a class="navbar-item menu-item" [routerLink]="[ '/' ]" routerLinkActive="has-text-wight-bold">
                HOME
            </a>
            <!-- <a class="navbar-item menu-item" [routerLink]="[ '/announcements' ]" routerLinkActive="has-text-weight-bold">
                소개
            </a> -->

            <a class="navbar-item menu-item" [routerLink]="[ '/weather' ]" routerLinkActive="has-text-weight-bold">
                기상
            </a>

            <a class="navbar-item menu-item" [routerLink]="[ '/atmospheric_environment' ]" routerLinkActive="has-text-weight-bold">
                대기 환경
            </a>

            <a class="navbar-item menu-item" [routerLink]="[ '/city' ]" routerLinkActive="has-text-weight-bold">
                도시 방재
            </a>
            <!-- <a class="navbar-item menu-item" [routerLink]="[ '/disaster_protection' ]" routerLinkActive="has-text-weight-bold">
                방재
            </a> -->

            <!-- <a class="navbar-item menu-item" [routerLink]="[ '/routine_data_collected' ]" routerLinkActive="has-text-weight-bold">
                ROUTINE DATA COLLECTED
            </a>
            <a class="navbar-item menu-item" [routerLink]="[ '/field_campaign_data_collected' ]" routerLinkActive="has-text-weight-bold">
                FIELD CAMPAIGN DATA COLLECTED
            </a>
            <a class="navbar-item menu-item" [routerLink]="[ '/admin' ]" routerLinkActive="has-text-weight-bold" *ngIf="auth.hasStaffRole()">
                ADMIN
            </a> -->
        </div>

        <div class="navbar-end">
            <!-- <div class="navbar-item">
                <div class="buttons has-addons are-small is-right">
                    <ng-container *ngFor="let lang of langs ; let i = index">
                        <button class="button is-white" (click)="changeLang(lang)">
                            <img [src]="'assets/images/flag/' + countries[i] + '-flag-small.png'" [ngClass]="{'current-lang': currentLang == lang}">
                        </button>
                    </ng-container>
                </div>
            </div> -->

            <!-- <div class="navbar-item">
                <ng-container *ngIf="!auth.hasAuthentication(); else elseTemplate">
                    <div class="buttons are-small is-right">
                        <a class="button is-light" [routerLink]="[ '/signin' ]">
                            Sign in
                        </a>
                        <a class="button is-link has-text-weight-bold" [routerLink]="[ '/signup' ]">
                            Sign up
                        </a>
                    </div>                    
                </ng-container>
                <ng-template #elseTemplate>
                    <div class="buttons are-small is-right">
                        <a class="button is-light" [routerLink]="[ '/profile']">
                            Profile
                        </a>
                        <a class="button is-link has-text-weight-bold" (click)="signout()">
                            Sign out
                        </a>
                    </div>                      
                </ng-template>       
            </div> -->
        </div>
    </div>
</nav>