<div class="modal is-active" *ngIf="active">
    <div class="modal-background"></div>
    <div class="modal-card">
        <section class="modal-card-body">
            <div class="modal-card-content">
                <ng-content select="[content]"></ng-content>
            </div>
            <ng-content select="[buttons]"></ng-content>
        </section>
    </div>
</div>