import { Component } from '@angular/core';
import { AuthService } from 'src/app/http/auth.service';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-members-wating',
  templateUrl: './wating.component.html',
  styleUrls: ['./wating.component.css']
})
export class WaitingComponent {
  readonly progressBarId = "editor";

  userWating:any = null;
  serviceSubscription: Subscription;
  userCountPerPage = 10;
  current = 1;
  max = 1;

  constructor(public auth: AuthService) { }

  ngOnInit():void {    
    this.serviceSubscription = this.auth.list({permit: false})
      .subscribe(res => {
        this.userWating = res as [];
        this.max = Math.ceil(this.userWating.length / this.userCountPerPage);
      }, (error: HttpErrorResponse) => {
      } 
    );

  }  

  data() {
    if(this.userWating != null)
      return this.userWating.slice(this.userCountPerPage * (this.current - 1), this.userCountPerPage * (this.current));
  }

  permit(event: MouseEvent, user: any) {
    event.stopPropagation();

    user.permit = true;
    delete user.grantedauthoritylist;

    this.auth.permit(user);
  }

  deny(event: MouseEvent, id: string) {
    event.stopPropagation();

    this.auth.remove(id);
  }

  pageChange(page) {
    this.current = page.current;
  }

  ngOnDestroy(): void {
    this.serviceSubscription.unsubscribe();
  }

}
