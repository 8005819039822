var fileTypeWithExtensionCSV = {
    image: ".bmp,.jpg,.jpeg,.gif,.png,.tif,.tiff,.raw,.psd,.ai,.svg,.webp",
    audio: ".wav,.mp3,.ogg,.wma,.au,.rm,.mid,.midi,.flac,.ape,.aac,.aiff",
    video: ".mkv,.avi,.mp4,.mpg,.mpeg,.flv,.wmv,.asf,.asx,.ogm,.ogv,.mov,.ts,.webm,skm",
    document: "..doc,.docx,.ppt,.pptx,.xls,.xlsx,.pdf,.hwp,.hwpx,.txt,.md,.markdown",
    archive: ".alz,.ace,.arc,.arj,.b64,.bh,.bhx,.bin,.bz2,.cab,.ear,.enc,.gz,.ha,.hqx,.ice,.img,.jar,.lha,.mim,.pak,.rar,.sit,.tar,.tgz,.ar,.gz,.uue,.war,.xxe,.z,.zip,.zoo"
}

export enum FileType {
    IMAGE, AUDIO, VIDEO, DOCUMENT, ARCHIVE, ALL, NONE
}
export namespace FileType {
    export function toString(fileType: FileType): string {
        return FileType[fileType];
    }

    export function parse(fileType: string): FileType {
        return fileType ? FileType[fileType?.toUpperCase()] : FileType.ALL
    }

    // file upload type 지정할때 필요
    export function toExtensionCSV(...fileTypes: FileType[]): string {
        if (fileTypes == null || fileTypes.some(ft => ft == FileType.ALL || ft == null)) {
            return Object.keys(fileTypeWithExtensionCSV)
                .map(ftk => fileTypeWithExtensionCSV[ftk])
                .join(",");
        }
        else {
            return fileTypes
                .map(ft => toString(ft))
                .map(s => fileTypeWithExtensionCSV[s.toLowerCase()])
                .join(",");
        }
    }

    function fileNameToFileTypeString(fileName: string): string {
        const typeString = Object.keys(fileTypeWithExtensionCSV)
            .map(ftk => ({
                key: ftk,
                target: fileTypeWithExtensionCSV[ftk]
            }))
            .map(kv => {
                kv.target = new RegExp("(" + kv.target.split(",").join("|") + ")$");
                return kv;
            })
            .filter(obj => fileName.toLowerCase().match(obj.target))[0]?.key;
        
        return typeString;
    }

    export function fileNameToFileType(fileName: string) {
        return parse(fileNameToFileTypeString(fileName)?.toUpperCase());
    }

    export function fileNameToIconClass(fileName: string): string {
        const typeString = fileNameToFileTypeString(fileName);
        
        if (typeString == null) {
            return "fa-file"
        }
        else if (typeString == 'document') {
            return "fa-file-alt"
        }
        else {
            return "fa-file-" + typeString
        }
    }
}