import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CopyrightModule } from 'src/app/common/copyright/copyright.module';
import { CustomPipeModule } from 'src/app/common/pipe/custom-pipe.module';
import { AdminModule } from './admin/admin.module';
import { AtmosphericEnvironmentModule } from './atmospheric-environment/atmospheric-environment.module';
import { CityModule } from './city/city.module';
import { ContentWithSidebarComponent } from './content-with-sidebar.component';
import { DisasterProtectionModule } from './disaster-protection/disaster-protection.module';
import { WeatherAnalysisModule } from './weather-analysis/weather-analysis.module';

@NgModule({
  declarations: [ContentWithSidebarComponent],
  imports: [
    CommonModule,
    RouterModule,
    CustomPipeModule,
    CopyrightModule,
    
    WeatherAnalysisModule,
    AtmosphericEnvironmentModule,
    CityModule,
    DisasterProtectionModule,

    AdminModule
  ]
})
export class ContentWithSidebarModule { }
