<div class="table-container">
    <table class="table is-fullwidth is-hoverable">
        <thead>
            <tr>
                <th class="has-text-centered" *ngFor="let header of headers">{{ header }}</th>
            </tr>
        </thead>

        <tbody *ngFor="let element of data; let i=index">
            <tr class="collapsible-header" (click)="toggle(i)">
                <!-- td -->
                <ng-container *ngIf="rowTemplateRef" [ngTemplateOutlet]="rowTemplateRef"
                    [ngTemplateOutletContext]="{$implicit:element, index:i}">
                </ng-container>
            </tr>
            <tr [ngClass]="{'is-hidden': expandedIndex != i}">
                <td [colSpan]="headers.length">
                    <div class="collapsible-body">
                        <!-- everything! -->
                        <ng-container *ngIf="rowContentTemplateRef" [ngTemplateOutlet]="rowContentTemplateRef"
                            [ngTemplateOutletContext]="{$implicit:element}">
                        </ng-container>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>