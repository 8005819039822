<app-collapsible-table [data]="data" [headers]="headers">
    <ng-template let-element let-index="index" #row>
        <td>{{ element.name }}</td>
        <td>{{ element.charge }}</td>
        <td>{{ element.organization }}</td>
        <td>{{ element.email }}</td>
        <td>{{ element.address }}</td>
        <td>
            <ng-container *ngIf="buttonsTemplateRef" [ngTemplateOutlet]="buttonsTemplateRef"
                [ngTemplateOutletContext]="{$implicit:element, index:index}">
            </ng-container>
        </td>
    </ng-template>
    <ng-template let-element #rowContent>
        <div class="collapsible-body">
            <div class="columns is-multiline is-mobile">
                <div class="column is-half" *ngFor="let key of detailHeaders">
                    <div class="tags has-addons">
                        <span class="tag is-primary">{{ key }}</span>
                        <span class="tag">{{ element[key] }}</span>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</app-collapsible-table>