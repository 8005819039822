import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as dateFns from 'date-fns';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-south-korea',
  templateUrl: './south-korea.component.html',
  styleUrls: ['./south-korea.component.css']
})
export class SouthKoreaComponent implements OnInit, OnChanges {

  @Input()
  date = new Date();

  data = {};

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.date) {
      this.update();
    }
  }

  update() {
    this.data = ['wsfc', 'wt85'].reduce((schema: any, key) => {
      schema[key] = this.getImageSources(key);
      return schema;
    }, {});
  }

  private getCommonUrl() {
    return [environment.sijaqApiUrl, 'gdps', dateFns.format(this.date, "yyyyMM"), this.formattedTodayString(), "notype"].join("/");
  }

  fillZero(num: number) {
    return num.toString().length < 3 ? '0' + num : num;
  }

  getImageSources(fileName?: string) {
    return [...Array(6).keys()].reduce((arr: any[], i) => {
      const src = [this.getCommonUrl(), "gdps_lc40_wtem_" + fileName + "_s" + this.fillZero((i + 1) * 24) + "_" + this.formattedTodayString()].join("/")+ "00.gif";
      
      arr.push({
        title: 'Day +' + (i + 1),
        src: src
      });

      return arr;
    }, []);
  }


  formattedTodayString() {
    return dateFns.format(this.date, "yyyyMMdd");
  }
}
