import { Component } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { ProgressService } from './common/progress/progress.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  private readonly progressBarId = 'navigate';

  constructor(router: Router, private progress: ProgressService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    // translate.setDefaultLang('ko');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    // translate.use('ko');

    router.events.subscribe(event => this.handleRouteEvent(event));
  }

  ngOnInit(): void {
    this.progress.event(this.progressBarId).subscribe(({ timeout }) => {
      if (!timeout)
        return;

      /* this.popup.show("오류가 발생했습니다. 다시 시도해주세요.", () => {
        this.progress.stop();
      }); */
    });
  }

  private handleRouteEvent(event) {
    // FIXME Guard
    if (event instanceof NavigationStart) {
      this.progress.show(this.progressBarId, {
        errorLimitSec: 10
      });
    }

    const isNavigationEnd = [NavigationEnd, NavigationCancel, NavigationError].some(n => event instanceof n);

    if (isNavigationEnd) {
      this.progress.hide();
      window.scrollTo(0, 0);
    }
  }
}
