import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TimeRangeSliderModule } from 'src/app/common/calendar/time-range-slider/time-range-slider.module';
import { DefaultImageModule } from 'src/app/common/default-image/default-image.module';
import { ValidationModule } from 'src/app/common/validation/validation.module';
import { CmaqComponent } from './cmaq.component';

@NgModule({
  declarations: [
    CmaqComponent
  ],
  imports: [
    CommonModule,
    ValidationModule,
    ReactiveFormsModule,
    TimeRangeSliderModule,
    DefaultImageModule
  ]
})
export class CmaqModule { }
