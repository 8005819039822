<div [formGroup]="form" class="field field-container">
    <label class="label">{{ label || (controlName | titlecase) }}</label>
    <div class="control has-icons-left has-icons-right">
        <input class="input" [ngClass]="validator.classValue(form, controlName)" [type]="type || 'text'"
            [placeholder]="placeholder || (controlName | titlecase)" [formControlName]="controlName" [attr.disabled]="disabled" required>
        <span class="icon is-small is-left">
            <i [ngClass]="'fas ' + iconClass"></i>
        </span>
        <app-validation-icon [form]="form" [key]="controlName"></app-validation-icon>
    </div>
    <p class="help is-danger" *ngIf="invalidMessage && validator.isShowHelper(form, controlName)">
        {{ invalidMessage }}
    </p>
</div>