import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CityComponent } from './city.component';
import { RouterModule } from '@angular/router';
import { GalleryModule } from './gallery/gallery.module';
import { DataReportsModule } from './data-reports/data-reports.module';

@NgModule({
  declarations: [
    CityComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    GalleryModule,
    DataReportsModule
  ]
})
export class CityModule { }
