import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidationIconComponent } from './validation-icon/validation-icon.component';
import { ValidatorService } from './validation.service';
import { ReactiveFormsModule } from '@angular/forms';
import { InputFieldComponent } from './input-field/input-field.component';
import { RadioFieldComponent } from './radio-field/radio-field.component';

@NgModule({
  declarations: [ValidationIconComponent, InputFieldComponent, RadioFieldComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  providers: [ValidatorService],
  exports: [ReactiveFormsModule, ValidationIconComponent, InputFieldComponent, RadioFieldComponent]
})
export class ValidationModule { }
