import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as dateFns from 'date-fns';

@Component({
  selector: 'app-east-asia',
  templateUrl: './east-asia.component.html',
  styleUrls: ['./east-asia.component.css']
})
export class EastAsiaComponent implements OnInit, OnChanges {

  rootUrl = "https://www.weather.go.kr/w/repositary/image/cht/img/";

  @Input()
  date = new Date();

  data = {};

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.date) {
      this.update();
    }
  }

  update() {
    this.data = ['surfce', 'gph850', 'ttd700', 'gph500'].reduce((schema: any, key) => {
      schema[key] = this.getImageSources(key);
      return schema;
    }, {});
  }

  fillZero(num: number) {
    return num.toString().length < 3 ? '0' + num : num;
  }

  private getImageSources(fileName: string) {
    return [...Array(6).keys()].reduce((arr: any[], i) => {
      const src = [this.rootUrl + "kim_gdps_erly_asia", fileName, "ft06_pa4", "s" + this.fillZero(((i + 1) * 24)), dateFns.format(this.date, "yyyyMMdd")].join("_") + "00.png";

      arr.push({
        title: 'Day +' + (i + 1),
        src: src
      });

      return arr;
    }, []);
  }
}
