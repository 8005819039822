import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DefaultImageModule } from 'src/app/common/default-image/default-image.module';
import { ImageColumnsModule } from 'src/app/common/image-columns/image-columns.module';
import { SouthKoreaComponent } from './south-korea.component';

@NgModule({
  declarations: [
    SouthKoreaComponent
  ],
  imports: [
    CommonModule,
    DefaultImageModule,
    ImageColumnsModule
  ],
  exports: [
    SouthKoreaComponent
  ]
})
export class SouthKoreaModule { }
