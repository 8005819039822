import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImageColumnsModule } from 'src/app/common/image-columns/image-columns.module';
import { WeatherMapComponent } from './weather-map.component';

@NgModule({
  declarations: [WeatherMapComponent],
  imports: [
    CommonModule,
    ImageColumnsModule
  ],
  exports: [ 
    WeatherMapComponent
  ]
})
export class WeatherMapModule { }
