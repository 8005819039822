import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/http/auth.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-members-permitted',
  templateUrl: './permitted.component.html',
  styleUrls: ['./permitted.component.css']
})
export class PermittedComponent {

  isShowModalForRemoveUser: boolean = false;
  private userInfo;
  userPermit:any;
  serviceSubscription: Subscription;
  userCountPerPage = 10;
  current = 1;
  max = 1;

  constructor(public auth: AuthService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit():void {
    this.serviceSubscription = this.auth.list({permit: true})
        .subscribe(res => {
          this.userPermit = res as [];
          this.max = Math.ceil(this.userPermit.length / this.userCountPerPage);
        }, (error: HttpErrorResponse) => {
      } 
    );
  }  

  data() {
    if(this.userPermit != null)
      return this.userPermit.slice(this.userCountPerPage * (this.current - 1), this.userCountPerPage * (this.current));
  }
  
  edit(event: MouseEvent, userInfo: any) {
    event.stopPropagation();

    this.router.navigate([ '/admin', 'members', 'edit'], {
      relativeTo: this.route,
      queryParams: { email: userInfo.email },
    });
  }

  showDialogForRemoveUser(event: MouseEvent, userInfo: any) {
    event.stopPropagation();
    this.isShowModalForRemoveUser = true;
    this.userInfo = userInfo;
  }

  remove() {
    this.auth.remove(this.userInfo['id']);
  }

  pageChange(page) {
    this.current = page.current;
  }

  ngOnDestroy(): void {
    this.serviceSubscription.unsubscribe();
  }

  isRootUser({ email }) {
    return email === "root@root.com";
  }
}
