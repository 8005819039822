import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProgressService } from 'src/app/common/progress/progress.service';
import { Control } from 'src/app/common/select/select.component';
import { SelectService } from 'src/app/common/select/select.service';
import { AuthService } from 'src/app/http/auth.service';
import { PostService } from 'src/app/http/post.service';
import { Post } from 'src/app/store/schema';
import { RichText } from '../../domain';
import { FileType } from '../../file-type.enum';
import { Mode } from '../../mode.enum';
import { RichTextEditorComponent } from '../rich-text-editor.component';

@Component({
  selector: 'app-enhanced-rich-text-editor',
  templateUrl: './enhanced-rich-text-editor.component.html',
  styleUrls: ['./enhanced-rich-text-editor.component.css']
})
export class EnhancedRichTextEditorComponent extends RichTextEditorComponent implements OnInit {
  readonly progressBarId = "editor";

  data: Post;
  storeKey: string;
  editor: string;

  targetFileType: FileType;

  controls: Control[][];

  constructor(protected route: ActivatedRoute, protected router: Router, protected fb: FormBuilder,
    protected post: PostService, protected progress: ProgressService, protected auth: AuthService, public select: SelectService) {
    super(route, router, fb, select);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.id = this.route.snapshot.params.id;

    console.log(this.id);

    const routeData = this.route.snapshot.data;
    this.storeKey = routeData.storeKey ?? this.router.url.split("/")[2];

    if (this.isEditMode()) {
      this.route.snapshot.data?.["data"].subscribe(data => this.data = data);
    }

    this.targetFileType = routeData.fileType ?? FileType.ALL;

    this.progress.event(this.progressBarId).subscribe(({ timeout }) => {
      if (!timeout)
        return;

      /* this.popup.show("오류가 발생했습니다. 다시 시도해주세요.", () => {
        this.progress.stop();
      }); */
    });
  }

  isEditMode(): boolean {
    return this.id != null;
  }

  get editorMode() {
    return this.isEditMode() ? Mode.EDIT : Mode.WRITE;
  }

  savePostToServer(richText: RichText) {
    this.progress.show(this.progressBarId, {
      errorLimitSec: 10
    });

    if (this.isEditMode())
      this.editPost(richText);
    else
      this.createPost(richText);
  }

  get parentUrl(): string[] {
    return this.route.snapshot.pathFromRoot
      .filter(snapshot => snapshot.url.length > 0)
      .map(snapshot => snapshot.url[0].path).slice(0, 2);
  }

  // 필요한 경우 override
  getDtoForCreate(richText: RichText): Post {
    return {
      // writer: this.auth.who(),
      // editor: this.auth.who(),
      writer: 'writer',
      editor: 'editor',
      title: richText.title,
      content: richText.content,
      type: richText.type
    };
  }

  createPost(richText: RichText) {
    let post: Post = this.getDtoForCreate(richText);
    this.post.create(this.storeKey, {
      post: post,
      files: richText.files
    }).subscribe(_resp => {
      this.router.navigate(this.parentUrl);
    }, err => {
      console.log(err)
      alert(err);
    }, () => {
      this.progress.hide();
    });
  }

  onChangeUploadedFiles(files) {
    this.data.files = files;
  }

  // 필요한 경우 override
  getDtoForEdit(richText: RichText): Post {
    let post: Post = JSON.parse(JSON.stringify(this.data));
    //post.editor = this.auth.who();
    post.editor = "editor"
    post.title = richText.title;
    post.content = richText.content;
    post.type = richText.type,

      delete post.createdAt;
    delete post.updatedAt;

    return post;
  }

  editPost(richText: RichText) {
    let post: Post = this.getDtoForEdit(richText);
    console.log(post);

    this.post.edit(this.storeKey, {
      post: post,
      files: richText.files
    }).subscribe(_resp => {
      this.router.navigate(this.parentUrl);
    }, err => {
      console.log(err);
      alert(err);
    }, () => {
      this.progress.hide();
    });
  }
}
