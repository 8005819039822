import { Component, Input } from '@angular/core';

export interface ImageSchema {
  title: string
  src: string
}

@Component({
  selector: 'app-image-columns',
  templateUrl: './image-columns.component.html',
  styleUrls: ['./image-columns.component.css']
})
export class ImageColumnsComponent {

  @Input()
  data?: ImageSchema[];

  @Input()
  column: 1 | 2 | 4 | 6 | 12 = 1;

  @Input()
  restPolicy: 'division' | 'full' = 'division';

  getColumnStyle(subColumnsIndex: number) {
    const subColumns = this.getSubColumns(subColumnsIndex);
    const columnCount = this.column as number;

    if (subColumns.length >= columnCount) {
      return `is-${12 / columnCount}`;
    }
    else {
      return this.restPolicy == 'full' ? 'is-12' : '';
    }
  }

  getSubColunmsIndices() {
    if (this.data == null || this.data.length <= 0)
      return [];

    return [...Array(Math.ceil(this.data.length / this.column)).keys()];
  }

  getSubColumns(subColumnsIndex: number) {
    const startIndex = subColumnsIndex * (this.column as number);
    let endIndex = startIndex + (this.column as number);
    if (endIndex >= this.data.length) {
      endIndex = this.data.length;
    }

    return this.data.slice(startIndex, endIndex);
  }
}
