import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UnderConstructionModule } from 'src/app/common/under-construction/under-construction.module';
import { DisasterProtectionComponent } from './disaster-protection.component';

@NgModule({
  declarations: [DisasterProtectionComponent],
  imports: [
    CommonModule,
    UnderConstructionModule
  ]
})
export class DisasterProtectionModule { }
