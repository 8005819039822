import { Component, Input } from '@angular/core';
import * as dateFns from 'date-fns';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-meteogram',
  templateUrl: './meteogram.component.html',
  styleUrls: ['./meteogram.component.css']
})
export class MeteogramComponent {

  @Input()
  date = new Date();

  private getCommonUrl() {
    return [environment.sijaqApiUrl, 'gdps', dateFns.format(this.date, "yyyyMM"), dateFns.format(this.date, "yyyyMMdd"), "notype"].join("/");
  }

  private getCommonFileName() {
    return dateFns.format(this.date, "yyyyMMdd") + "00.gif";
  }

  getImageSource() {
    const commonUrl = this.getCommonUrl();
    const commonFileName = this.getCommonFileName();
    return [commonUrl, "gdps_erly_city_47152_t072_" + commonFileName].join("/")
  }
}
