import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ValidatorService } from 'src/app/common/validation/validation.service';
import { AuthService } from 'src/app/http/auth.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {

  form: FormGroup;
  emailErrorMessage: string;
  loginErrorMessage: string;
  invalidPasswordMessage = "Must be at least 8 characters, including letters (case sensitive), numbers, and at least 1 special character.";

  constructor(private fb: FormBuilder, public auth: AuthService, public validator: ValidatorService, private router: Router) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, this.validator.emailValidator()]],
      password: ['', [Validators.required, this.validator.passwordValidator()]]
    });
  }

  submit() {
    const value = this.form.value;
    this.auth.login(value).subscribe(_response => {
      if(_response != null)
        // this.router.navigateByUrl("/");
        window.location.href = "/";
    }, (error: HttpErrorResponse) => {
      if(error.status == 401 || error.status == 405) {
        this.loginErrorMessage = "Invalid user. Please sign up or Wait for your approval.";
      }    
  }); 
  }
}
