import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GalleryComponent } from './gallery.component';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { RichTextModule } from '../../../../../common/rich-text/rich-text.module';
import { NgxBulmaPaginationModule } from 'ngx-bulma-pagination';
import { HammerModule } from '@angular/platform-browser';
import { ModalModule } from '../../../../../common/modal/modal.module';
import { GalleryViewerComponent } from './viewer/viewer.component';



@NgModule({
  declarations: [
    GalleryComponent,
    GalleryViewerComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    RichTextModule,
    NgxBulmaPaginationModule,
    HammerModule,
    ModalModule
  ],
  exports: [
    GalleryComponent
  ]
})
export class GalleryModule { }
