<p class="subtitle has-text-weight-bold is-size-3">[Forecast-East Asia] Weather Chart (SFC)</p>
<app-image-columns [data]="data['surfce']" [column]="2"></app-image-columns>

<p class="subtitle has-text-weight-bold is-size-3">[Forecast-East Asia] Weather Chart (850hPa)</p>
<app-image-columns [data]="data['gph850']" [column]="2"></app-image-columns>

<p class="subtitle has-text-weight-bold is-size-3">[Forecast-East Asia] T-Td (700hPa)</p>
<app-image-columns [data]="data['ttd700']" [column]="2"></app-image-columns>

<p class="subtitle has-text-weight-bold is-size-3">[Forecast-East Asia] Weather Chart (500hPa)</p>
<app-image-columns [data]="data['gph500']" [column]="2"></app-image-columns>
