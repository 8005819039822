import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DateControlsModule } from '../common/calendar/date-controls/date-controls.module';
import { TimeRangeSliderModule } from '../common/calendar/time-range-slider/time-range-slider.module';
import { FooterComponent } from './footer/footer.component';
import { MainComponent } from './main.component';
import { NavComponent } from './nav/nav.component';
import { AuthModule } from './section/auth/auth.module';
import { ContentWithSidebarModule } from './section/content-with-sidebar/content-with-sidebar.module';
import { AnnouncementsModule } from './section/content-with-sidebar/news/announcements/announcements.module';
import { HomeModule } from './section/home/home.module';

@NgModule({
  declarations: [MainComponent, NavComponent, FooterComponent],
  imports: [
    CommonModule,
    RouterModule,
    HomeModule,
    AuthModule,
    AnnouncementsModule,
    ContentWithSidebarModule,
    DateControlsModule,
    TimeRangeSliderModule
  ],
  exports: [MainComponent]
})
export class MainModule { }
