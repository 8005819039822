import { ContentChild, Directive, Input, TemplateRef } from "@angular/core";

@Directive()
export class AbstractTable {
    @Input()
    data: any[];

    private _headers: string[] = null;

    @Input()
    set headers(hs) {
        this._headers = hs;
    }

    get headers() {
        return typeof(this.data) != "undefined" && typeof(this.data[0]) != "undefined" ? this._headers || Object.keys(this.data[0]) : this._headers;
    }

    @ContentChild('row', { static: false })
    rowTemplateRef: TemplateRef<any>;
}
