import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { PostService } from './post.service';
import { PresentationFilesService } from './presentation-files.service';

@Injectable({
  providedIn: 'root'
})
export class AuthResolver implements Resolve<any> {

  constructor(
    private postService: PostService,
    private Presentation: PresentationFilesService
  ) { }


  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    //사용하는 서비스가 다름
    if (state.url.split("/")[2] == "presentation_files") {
      return this.Presentation.read(state.url.split("/")[4], route["params"].id);
    } else {
      return this.postService.read(state.url.split("/")[2], route["params"].id);
    }
  }
}
