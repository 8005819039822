export enum userType {
    WG1, WG2
}

export enum role {
    MEMBER, STAFF
}

export enum nationality {
    KOREA, CHINA, JAPAN
}

export function enumToArray(value:any){
    let values:any = Object.values(value).slice(0, Object.values(value).length / 2);
    let valuesToLowerCase:string[] = [];
    values.map((n, idx) => {
       valuesToLowerCase[idx] = n.toLowerCase(); 
    });
    return valuesToLowerCase;
}

  