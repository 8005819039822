import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DateControlsModule } from 'src/app/common/calendar/date-controls/date-controls.module';
import { ImageColumnsModule } from 'src/app/common/image-columns/image-columns.module';
import { PandoraUnistComponent } from './pandora-unist.component';

@NgModule({
  declarations: [
    PandoraUnistComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    DateControlsModule,
    ImageColumnsModule
  ]
})
export class PandoraUnistModule { }
