import { Injectable, EventEmitter } from '@angular/core';

interface ProgressEvent {
  sec: number;
  data?: any;
  timeout: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ProgressService {
  isVisible = false;
  isError = false;
  errorCallback = null;

  private interval;
  private events: { string?: EventEmitter<ProgressEvent> } = {};

  constructor() {
    this.hide();
  }

  public event(id: string): EventEmitter<ProgressEvent> {
    if (!this.events[id]) {
      this.events[id] = new EventEmitter<ProgressEvent>();
    }

    return this.events[id];
  }

  public unsubscribe(id: string) {
    if (!this.events[id])
      return;
    
    this.events[id].unsubscribe();
    delete this.events[id];
  }

  show(id: string, options?: { data?: any, errorLimitSec?: number, errorCallback?: Function }) {
    if (this.isVisible)
      return;;
    this.errorCallback = options?.errorCallback;

    let sec = 0;
    this.interval = setInterval(() => {
      this.event(id).emit({
        sec: ++sec,
        data: options?.data,
        timeout: options?.errorLimitSec !== null && options?.errorLimitSec <= sec
      });

      if (options?.errorLimitSec !== null && options?.errorLimitSec <= sec) {
        this.isError = true;
      }
    }, 1000);

    this.isVisible = true;
  }

  hide() {
    this.isVisible = false;
    this.isError = false;
    this.errorCallback = null;
    clearInterval(this.interval);
  }
}
