import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TableModule } from 'src/app/common/table/table.module';
import { UnderConstructionModule } from 'src/app/common/under-construction/under-construction.module';
import { AnnouncementsComponent } from './announcements.component';

@NgModule({
  declarations: [AnnouncementsComponent],
  imports: [
    CommonModule,
    RouterModule,
    TableModule,
    ReactiveFormsModule,
    UnderConstructionModule
  ]
})
export class AnnouncementsModule { }
