import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ValidatorService } from '../validation.service';

@Component({
  selector: 'app-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.css']
})
export class InputFieldComponent {

  @Input()
  form: FormGroup;

  @Output()
  formChange = new EventEmitter<FormGroup>();

  @Input()
  controlName: string;

  @Input()
  iconClass: string;

  // below optional

  @Input()
  type: string;

  @Input()
  label: string;

  @Input()
  placeholder: string;

  @Input()
  invalidMessage: string;

  @Input()
  disabled: boolean | any;

  constructor(public validator: ValidatorService) { }
}
