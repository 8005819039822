<div class="mt-5">
  <app-date-controls [date]="currentDate" (dateChange)="changeDate($event)"></app-date-controls>
</div>

<div class="mt-3 mb-5" *ngIf="!isHiddenSlider">
  <hr>

  <div class="mb-6">
      <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="options"
          (userChangeEnd)="changeSliderValue($event)">
      </ngx-slider>
  </div>

  <div class="columns is-vcentered is-centered">
      <div class="column">
          <!-- <span class="has-text-weight-semibold mr-2">Speed</span> -->

          <!-- <form [formGroup]="trsForm">
              <label class="mx-1" *ngFor="let speed of speedValues">
                  <input class="radio is-small is-info" type="radio" [value]="speed" formControlName="speed">
                  <span>{{speed}}x</span>
              </label>
          </form> -->
<!--
          <div class="radio-buttons">
              <app-radio-buttons [controls]="speedControls" [alignment]="'left'" (valueChange)="speed = $event"></app-radio-buttons>
          </div> -->
      </div>

      <div class="column is-6">

          <div class="buttons has-addons is-centered">
              <button class="button is-small" (click)="clickPlayControls('backward')">
                  <i class="fas fa-step-backward"></i>
              </button>

              <button class="button is-small has-text-weight-semibold" (click)="clickPlayControls('-' + step2)" *ngIf="step2 != interval">
                  -{{ step2 }}{{ this.unit }}
              </button>

              <button class="button is-small has-text-weight-semibold"
                  (click)="clickPlayControls(currentIntervalHour(false))">
                  {{ currentIntervalHour(false) }}
              </button>

              <button class="button is-small" (click)="clickPlayControls('play')" *ngIf="!play"
                  [disabled]="!isAvailable">
                  <i class="fas fa-play"></i>
              </button>

              <button class="button is-small" (click)="clickPlayControls('pause')" *ngIf="play">
                  <i class="fas fa-pause"></i>
              </button>

              <button class="button is-small has-text-weight-semibold"
                  (click)="clickPlayControls(currentIntervalHour(true))">
                  {{ currentIntervalHour(true) }}
              </button>

              <button class="button is-small has-text-weight-semibold" (click)="clickPlayControls('+' + step2)" *ngIf="step2 != interval">
                  +{{ step2 }}{{ this.unit }}
              </button>

              <button class="button is-small" (click)="clickPlayControls('forward')">
                  <i class="fas fa-step-forward"></i>
              </button>

              <button class="button is-small ml-2" (click)="clickPlayControls('reset')">
                  <i class="fas fa-undo-alt"></i>
              </button>
          </div>
      </div>

      <div class="column">
          <div class="has-text-right" *ngIf="isShownInterval">
              <!-- <span class="has-text-weight-semibold mr-2">Interval</span> -->

              <!-- <form [formGroup]="trsForm">
                  <label class="mx-1" *ngFor="let interval of intervalValues">
                      <input class="radio is-small is-info" type="radio" [value]="interval"
                          formControlName="interval">
                      <span>{{ interval }}h</span>
                  </label>
              </form> -->
<!--
              <div class="radio-buttons">
                  <app-radio-buttons [controls]="intervalControls" [alignment]="'right'" (valueChange)="intervalFormValue = $event"></app-radio-buttons>
              </div> -->
          </div>
      </div>
  </div>
</div>
