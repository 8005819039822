import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateControlsComponent } from './date-controls.component';
import { CalendarModule } from '../calendar.module';



@NgModule({
  declarations: [
    DateControlsComponent
  ],
  imports: [
    CommonModule,
    CalendarModule
  ],
  exports: [DateControlsComponent]
})
export class DateControlsModule { }
