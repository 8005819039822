import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiGateway } from './api-gateway';

@Injectable({
  providedIn: 'root'
})
export class OpenApiService extends ApiGateway {

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  protected get apiUrl(): string {
    return environment.sijaqApiUrl + "/open-api";
  }

  list(value){
    return this.post("list", value);
  } 
}
