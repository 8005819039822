import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SatAndRadarComponent } from './sat-and-radar.component';



@NgModule({
  declarations: [SatAndRadarComponent],
  imports: [
    CommonModule
  ],
  exports: [
    SatAndRadarComponent
  ]
})
export class SatAndRadarModule { }
