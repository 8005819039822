import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PostService } from 'src/app/http/post.service';
import { Post } from 'src/app/store/schema';
import { StoreService } from 'src/app/store/store.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  subscription: Subscription;
  private storeKey;

  data: any[];
  max = 0;
  current = 1;

  constructor(private post: PostService, private store: StoreService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.storeKey = this.route.snapshot.data.storeKey ?? this.router.url.split("/")[2];
    this.requestData();    
  }

  requestData() {
    this.subscription?.unsubscribe();
    this.subscription = this.post.readAll(this.storeKey, this.current, {size: 4}).subscribe(({ content, totalPages }) => {
      this.data = content;
      this.max = totalPages;
    });
  }

  hasImages(gallery: Post) {
    return gallery.files?.length > 0;
  }

  filesToImageSources(gallery: Post) {
    return this.hasImages(gallery) ? gallery.files.map(f => f.url) : ["https://via.placeholder.com/550x400?text=No+image"];
  }

  open(gallery: Post) {
    this.router.navigate(["city", this.storeKey, "view", gallery.id], { relativeTo: this.route });
  }

  imageCount(gallery: Post) {
    return this.hasImages(gallery) ? gallery.files.length : 0;
  }

}
