import { Component, OnInit } from '@angular/core';
import * as dateFnsFp from "date-fns/fp";
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-kalion-korea',
  templateUrl: './kalion-korea.component.html',
  styleUrls: ['./kalion-korea.component.css']
})
export class KalionKoreaComponent implements OnInit {

  key = "observation.remotesensing.kalion";
  schema: any[] = [
    {
      title: "Backscattering Coefficient",
      value: "ABC"
    },
    {
      title: "Mass concentration",
      value: "Mass"
    },
    {
      title: "Polarization",
      value: "TDR"
    },
    {
      title: "Classification",
      value: "Type"
    },
    {
      title: "Extinction Coefficient",
      value: "EXT"
    }
  ];

  data?: any[];

  date: Date = new Date();

  ngOnInit(): void {
    this.update();
  }

  update() {
    //Ulsan_Type_20221103-20221109.jpg
    this.data = this.schema.reduce((arr, obj) => {
      obj['src'] = `${environment.finepApiUrl}/Ulsan_${obj.value}_${this.getFormattedRangeString()}.jpg`;
      arr.push(obj);
      return arr;
    }, []);
    //console.log(this.data)
  }

  getFormattedRangeString() {
    const formatter = dateFnsFp.format("yyyyMMdd");
    return [
      _.flow(
        dateFnsFp.subDays(6),
        formatter
      )(this.date),
      _.flow(
        formatter
      )(this.date)
    ].join("-");
  }

  onChangedDate(date: Date) {
    this.date = date;
    this.update();
  }
}
