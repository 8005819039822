import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeRangeSliderComponent } from './time-range-slider.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DateControlsModule } from '../date-controls/date-controls.module';
import { NgxSliderModule } from '@angular-slider/ngx-slider';



@NgModule({
  declarations: [
    TimeRangeSliderComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DateControlsModule,
    NgxSliderModule
  ],
  exports: [
    TimeRangeSliderComponent
  ]
})
export class TimeRangeSliderModule { }
