import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataReportsComponent } from './data-reports.component';
import { ResourcesTableModule } from 'src/app/common/table/resources-table/resources-table.module';



@NgModule({
  declarations: [
    DataReportsComponent
  ],
  imports: [
    CommonModule,
    ResourcesTableModule
  ]
})
export class DataReportsModule { }
