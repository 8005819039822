<section class="section">
    <div class="columns">
        <div class="column is-12-mobile is-6-tablet is-5-desktop is-4-fullhd is-offset-3-tablet is-offset-4-desktop is-offset-4-fullhd">
            <form class="card" [formGroup]="form">
                <section class="hero">
                    <div class="hero-body">
                        <div class="container">
                            <h1 class="title">
                                Sign in
                            </h1>
                        </div>
                    </div>
                </section>
                <div class="card-content">
                    <app-input-field [(form)]="form" [controlName]="'email'" [iconClass]="'fa-envelope'" [type]="'email'" [label]="'E-Mail'" [placeholder]="'E-Mail'"></app-input-field>
                    <app-input-field [(form)]="form" [controlName]="'password'" [iconClass]="'fa-key'" [type]="'password'" [invalidMessage]="invalidPasswordMessage"></app-input-field>
                    <div class="field field-container">
                        <div class="control">
                            <div class="buttons is-right">
                                <button type="submit" class="button is-fullwidth is-rounded is-outlined is-primary"
                                    (click)="submit()" >Submit</button>
                                    <p class="help is-danger">{{ loginErrorMessage }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
