import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as dateFnsFp from "date-fns/fp";
import { DateRange } from 'src/app/common/calendar/time-range-slider/time-range-slider.component';
import { ValidatorService } from 'src/app/common/validation/validation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cmaq',
  templateUrl: './cmaq.component.html',
  styleUrls: ['./cmaq.component.css']
})
export class CmaqComponent implements OnInit, AfterViewInit {

  form: FormGroup;

  // domains = ['27km', '09km', '03km'];
  domainSchema = [
    {
      value: '09km'
    },
    {
      value: '03km_metro',
      name: '03km'
    },
    {
      value: '03km_pk',
      name: '03km (울산)'
    }
  ]

  currentDate: Date = new Date();
  sliderDate: Date = new Date();
  verticalCurtainDate!: Date;

  constructor(private fb: FormBuilder, public validator: ValidatorService, private changeRef: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    this.changeRef.detectChanges();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      variable: ['PM25', [Validators.required]],
    });
  }

  isSelectedVertical() {
    return this.form.value.kind == 'vertical';
  }

  getImageSource(domain: string) {
    let variable = this.form.value.variable;

    let apiUrl = '';
    let dirs = '';
    const formattedDate = dateFnsFp.format("yyyy/MM/dd")(this.currentDate);

    let region = domain == "03km_pk" ? "PK" : domain == "03km_metro" ? "METROPOLITAN" : ""; 
    domain = domain.split("_")[0];

    apiUrl = environment.sijaqApiUrl;
    dirs = ["/image",
      formattedDate,
      "modeling",
      "cmaq",
      "spatial",
      domain,
      region.toLowerCase()
    ].join("/");

    const fileName = ["UNIST", "CMAQ", variable, domain, region != "" ? "SURFACE_"+region : "SURFACE", dateFnsFp.format("yyyyMMddHH")(this.sliderDate)].join("_") + ".png";
    return [apiUrl, dirs, fileName].join("/");
  }

  getReady(date: DateRange) {
    this.currentDate = date.current;
    this.sliderDate = date.start;
    this.verticalCurtainDate = date.start;
  }

  getDateChange(date: DateRange) {
    this.currentDate = date.current;
    this.sliderDate = date.start;
    this.verticalCurtainDate = date.start;
  }

  getValueChange(date: Date) {
    this.sliderDate = date;
  }

}
