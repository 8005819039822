import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as dateFns from 'date-fns';

@Component({
  selector: 'app-weather-map',
  templateUrl: './weather-map.component.html',
  styleUrls: ['./weather-map.component.css']
})
export class WeatherMapComponent implements OnInit, OnChanges {

  @Input()
  date = new Date();

  rootUrl = "https://www.kma.go.kr/repositary/image/cht/img/";

  schema: any[] = [
    {
      title: "SFC",
      value: "surf"
    },
    {
      title: "850hPa",
      value: "up85"
    },
    {
      title: "500hPa",
      value: "up50"
    }
  ];

  data?: any[];

  ngOnInit(): void {
    this.update();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.date) {
      this.update();
    }
  }

  update() {
    this.data = this.schema.reduce((arr, obj) => {
      obj['src'] = [this.rootUrl + obj.value, dateFns.format(this.date, "yyyyMMdd")].join("_") + "00.png"
      arr.push(obj);
      return arr;
    }, []);
  }
}
