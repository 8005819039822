import { Component, OnInit } from '@angular/core';
import { ProgressService } from './progress.service';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.css']
})
export class ProgressComponent {
  constructor(public progress: ProgressService) { }

  handlingError() {
    if (this.progress.errorCallback) {
      this.progress.errorCallback();
    }

    this.progress.hide();
  }
}
