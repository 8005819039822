import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DateControlsModule } from 'src/app/common/calendar/date-controls/date-controls.module';
import { ForecastComponent } from './forecast.component';
import { MeteogramModule } from './meteogram/meteogram.module';
import { NotificationModule } from './notification/notification.module';
import { EastAsiaModule } from './east-asia/east-asia.module';
import { SouthKoreaModule } from './south-korea/south-korea.module';

@NgModule({
  declarations: [
    ForecastComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    DateControlsModule,

    // PredictionModule,
    MeteogramModule,
    EastAsiaModule,
    SouthKoreaModule,
    NotificationModule
  ]
})
export class ForecastModule { }
