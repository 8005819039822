import { Component } from '@angular/core';

@Component({
  selector: 'app-forecast',
  templateUrl: './forecast.component.html',
  styleUrls: ['./forecast.component.css']
})
export class ForecastComponent {

  date: Date = new Date();

  onChangedDate(date: Date) {
    this.date = date;
  }
}
