import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { AbstractTable } from '../abstract-table';

@Component({
  selector: 'app-collapsible-table',
  templateUrl: './collapsible-table.component.html',
  styleUrls: ['./collapsible-table.component.css']
})
export class CollapsibleTableComponent extends AbstractTable {

  @ContentChild('rowContent', { static: false })
  rowContentTemplateRef: TemplateRef<any>;

  expandedIndex = null;

  toggle(index: number) {
    if (this.expandedIndex == null || this.expandedIndex != index) {
      this.expandedIndex = index;
    }
    else {
      this.expandedIndex = null;
    }
  }
}
