import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-validation-icon',
  templateUrl: './validation-icon.component.html',
  styleUrls: ['./validation-icon.component.css']
})
export class ValidationIconComponent {
  @Input()
  form: FormGroup

  @Input()
  key: string

  isValid(): boolean {
    return this.form.controls[this.key].valid;
  }
}
