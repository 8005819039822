<p class="subtitle has-text-weight-bold is-size-3">[Forecast-South Korea] Weather Chart (SFC)</p>
<app-image-columns [data]="data['wsfc']" [column]="2"></app-image-columns>

<p class="subtitle has-text-weight-bold is-size-3">[Forecast-South Korea] Weather Chart (850hPa)</p>
<app-image-columns [data]="data['wt85']" [column]="2"></app-image-columns>

<div class="columns">
    <div class="column my-6">
        <p class="subtitle has-text-weight-bold is-size-3">[Forecast-South Korea] Weather Chart (SFC-preci.)</p>
        <img [src]="'https://www.weather.go.kr/w/repositary/image/cht/img/kim_gdps_lc40_fxko4r1_'+formattedTodayString()+'00.png'">
    </div>
    <div class="column mx-6 my-6">
        <p class="subtitle has-text-weight-bold is-size-3">[Forecast-South Korea] Weather Chart (850hPa)</p>
        <img [src]="'https://www.weather.go.kr/w/repositary/image/cht/img/kim_gdps_lc20_fxko78_'+formattedTodayString()+'00.png'">
    </div>
</div>