<div class="title">
    Research Performance
</div>

<div class="columns">
    <div class="column">
        <div class="buttons is-right">
            <!-- *ngIf="auth.hasAuthentication()" -->
            <ng-container>
                <a class="button" [routerLink]="[ 'new' ]"> <!-- 임시 -->
                    <i class="fas fa-pen mr-2"></i>
                    <span>Upload</span>
                </a>
            </ng-container>
        </div>
        <div class="columns is-mobile is-multiline is-vcentered">
            <div class="column is-6-mobile is-4-tablet is-3-desktop is-3-fullhd" *ngFor="let gallery of data">
                <div class="card">
                    <div class="card-image is-clickable thumbnail-wrappper mx-2 my-1" (click)="open(gallery)">
                        <div class="thumbnail">
                            <div class="centered">
                                <img [src]="filesToImageSources(gallery)[0]">
                                <!-- <img [src]="filesToImageUrl(gallery)"> -->
                            </div>
                        </div>
                        <span class="image-count is-size-7 has-text-weight-semibold" *ngIf="hasImages(gallery)">+{{ imageCount(gallery) }}</span>
                    </div>
                    <div class="card-content">
                        <div class="mb-2">
                            <p class="title is-5">{{ gallery.title }}</p>
                        </div>
        
                        <div class="content">
                            <div>
                                <i class="far fa-eye fa-xs mr-1"></i>
                                <span class="is-size-7">{{ gallery.viewCount }}</span>
                            </div>
                            <div>
                                <i class="far fa-clock fa-xs mr-1"></i>
                                <span class="is-size-7">{{ gallery.updatedAt | date: 'yyyy-MM-dd HH:mm:ss' }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="mt-4" *ngIf="max > 0">
            <ngx-bulma-pagination [count]="articleCountPerPage" [max]="max" (pageChange)="pageChange($event)">
            </ngx-bulma-pagination>
        </div>
    </div>
</div>



