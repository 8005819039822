import { ChangeDetectorRef, Component } from '@angular/core';
import * as dateFns from 'date-fns';
import { DateRange } from 'src/app/common/calendar/time-range-slider/time-range-slider.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ambient-air-concentrations',
  templateUrl: './ambient-air-concentrations.component.html',
  styleUrls: ['./ambient-air-concentrations.component.css']
})
export class AmbientAirConcentrationsComponent {

  title = "Estimation of PM2.5 ground concentration using statistical techniques";
  fileName = "ground-korea-pm25";

  selectedDate: Date = new Date();

  date = new Date();

  constructor(private changeRef: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    this.changeRef.detectChanges();
  }

  getImageSource(): any {
    const fileName = [this.fileName, dateFns.format(this.selectedDate, "yyyyMMddHH") + ".png"].join("-");
    return environment.finepApiUrl + '/' + fileName;
  }

  getReady(date: DateRange) {
    this.selectedDate = date?.current;
  }

  getDateChange(date: DateRange) {
    this.selectedDate = date?.current;
  }

  getValueChange(date: Date) {
    this.selectedDate = date;
  }

  countDays() {
    const hour = this.selectedDate.getHours() - 9;

    const today = new Date();
    const dayDiff = dateFns.differenceInDays(today, dateFns.startOfYear(today)) + 1;

    return [this.selectedDate.getFullYear(), dayDiff, this.fillZero(hour)].join("_");
  }

  fillZero(num) {
    return num.toString().length < 2 ? '0' + num : num;
  }

}
