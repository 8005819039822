import { Component, OnInit } from '@angular/core';
import * as dateFns from "date-fns";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pandora-unist',
  templateUrl: './pandora-unist.component.html',
  styleUrls: ['./pandora-unist.component.css']
})
export class PandoraUnistComponent implements OnInit {

  schema: any[] = [
    {
      title: "Hourly NO2 Surface Concentration (KST)",
      value: "P150_NO2_Surface"
    },
    {
      title: "Hourly NO2 Total Vertical Column (KST)",
      value: "P150_NO2_TCD"
    },
    {
      title: "Hourly O3 Total Vertical Column (KST)",
      value: "P150_O3_TCD"
    }
  ];

  data?: any[];
  selectedDate: Date = new Date();

  ngOnInit(): void {
    this.update();
  }

  update() {
    //2022/11/09/Ulsan/Ulsan_P150_NO2_TCD_hourly_20221109.png
    this.data = this.schema.reduce((arr, obj) => {
      obj['src'] = environment.finepApiUrl + '/' + ["Ulsan", obj.value, "hourly",dateFns.format(this.selectedDate, "yyyyMMdd") +".png"].join("_");
      arr.push(obj);
      return arr;
    }, []);
  }

  onChangedDate(date: Date) {
    this.selectedDate = date;
    this.update();
  }
}
