<div class="columns">
    <div class="column ml-5 mr-0 is-2" *ngIf="menus.length > 0 ">
        <h1 class="title menu-item">{{ subTitle1 | uppercase }}</h1>
        <ul>
            <li *ngFor="let menu of menus">
                <a [routerLink]="[ rootUrl, menu.path ]">
                    <span class="sidebar-menu is-size-4 my-6" routerLinkActive="active-menu">
                        {{ menu?.title || adjustUrl(menu.path) }}
                    </span>
                </a>

                <div *ngIf="menu?.children">
                    <a [routerLink]="[ rootUrl, menu.path, submenu.path ]" *ngFor="let submenu of menu.children">
                        <h5 class="subtitle sidebar-submenu ml-5 is-6" routerLinkActive="active-menu">
                            {{ getSubTile(submenu) }}</h5>
                    </a> 
                </div>

            </li>
        </ul>
    </div>
    <div class="column ml-0 mr-5">
        <!-- <div class="mb-5">
            <span class="has-text-weight-bold is-size-3 menu-item"> {{ subTitle1 | uppercase }} </span>
            <span class="mx-1" *ngIf="this.subTitle2 != null && this.subTitle2.length > 0">-</span>
            <span class="has-text-weight-bold is-size-5 ml-1 menu-item"> {{ subTitle2 | uppercase }} </span>
        </div> -->
        <router-outlet></router-outlet>
    </div>
</div>