import { Component } from '@angular/core';
import * as dateFns from "date-fns";

@Component({
  selector: 'app-city',
  templateUrl: './city.component.html',
  styleUrls: ['./city.component.css']
})
export class CityComponent {
  date: Date = dateFns.startOfDay(new Date());

  onChangedDate(date: Date) {
    this.date = dateFns.startOfDay(date);
  }

}
