import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export abstract class ApiGateway {
    constructor(protected httpClient: HttpClient) { }

    protected abstract get apiUrl(): string;

    protected get(path: string, params?: any, header?: any) {
        if (header) {
            return this.httpClient.get(this.buildUrl(path, params), {
                headers: new HttpHeaders(header)
            });
        }
        else {
            return this.httpClient.get(this.buildUrl(path, params));
        }
    }

    protected download(path: string, fileName: string): Observable<any> {
        return this.httpClient.get(this.buildUrl(path), {
            responseType: 'blob',
            headers: new HttpHeaders({
                'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
                'Pragma': 'no-cache',
                'Expires': '0'
            })
        }).pipe(
            tap(
                blob => {
                    let a = document.createElement("a");
                    document.body.appendChild(a);
                    a.setAttribute("style", "display: none");
                    a.href = window.URL.createObjectURL(blob);
                    a.setAttribute('download', fileName);
                    a.click();
                    a.remove();
                }
            )
        );
    }

    protected post(path: string, body: any, header?: any) {
        if (header != null) {
            return this.httpClient.post(this.buildUrl(path), body,
                {
                    headers: new HttpHeaders(header)
                });
        }
        else {
            return this.httpClient.post(this.buildUrl(path), body);
        }
    }

    protected put(path: string, body: any, header?: any) {
        if (header != null) {
            return this.httpClient.put(this.buildUrl(path), body,
                {
                    headers: new HttpHeaders(header)
                });
        }
        else {
            return this.httpClient.put(this.buildUrl(path), body);
        }
    }

    protected patch(path: string, body: any, header?: any) {
        if (header != null) {
            return this.httpClient.patch(this.buildUrl(path), body,
                {
                    headers: new HttpHeaders(header)
                });
        }
        else {
            return this.httpClient.patch(this.buildUrl(path), body);
        }
    }

    protected delete(path: string, params?: any, header?: any) {
        if (header != null) {
            return this.httpClient.delete(this.buildUrl(path),
                {
                    headers: new HttpHeaders(header)
                });
        }
        else {
            return this.httpClient.delete(this.buildUrl(path));
        }
    }

    private buildUrl(path: string, params?: any): string {
        let urls = [this.apiUrl, path].join("/");
        if (params) {
            urls += this.objectToParam(params);
        }
        return urls;
    }

    private objectToParam(obj: any): string {
        let str = '';
        for (const key in obj) {
            if (obj[key] === null || obj[key] === undefined || obj[key].length === 0) { continue; }
            str += (str.length === 0 ? '?' : '&') + key + '=' + encodeURIComponent(obj[key]);
        }
        return str;
    }
}
