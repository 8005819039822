import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(public auth: AuthService) {}

  errorMessage: string;
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    const cloneReq = req.clone(this.auth.jwt != null ? {headers: new HttpHeaders().set('Authorization', this.auth.jwt)} : {});
    return next.handle(cloneReq).pipe(
          catchError(e => {            
        if(e.status == 401){
          this.errorMessage = "Please Log in Again";
          if(sessionStorage.getItem("token") != null) sessionStorage.removeItem("token");
        }else if(e.status == 403){
          this.errorMessage = "You Don't Have Permission";
        }else if(e.stats == 400){
          this.errorMessage = "Invalid Request.";    
        }

        console.log(this.errorMessage);

        return throwError(e);
      })
    );
  }
}
