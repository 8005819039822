import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AmbientAirConcentrationsModule } from './ambient-air-concentrations/ambient-air-concentrations.module';
import { CmaqModule } from './cmaq/cmaq.module';
import { KalionKoreaModule } from './kalion-korea/kalion-korea.module';
import { PandoraUnistModule } from './pandora-unist/pandora-unist.module';
import { AtmosphericEnvironmentComponent } from './atmospheric-environment.component';
import { CopyrightModule } from 'src/app/common/copyright/copyright.module';
import { RouterModule } from '@angular/router';
import { DefaultImageModule } from 'src/app/common/default-image/default-image.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DefaultImageModule,
    CopyrightModule,
    AmbientAirConcentrationsModule,
    KalionKoreaModule,
    PandoraUnistModule,
    CmaqModule
  ],
  declarations: [
    AtmosphericEnvironmentComponent
  ]
})
export class AtmosphericEnvironmentModule { }
