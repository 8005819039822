<p class="subtitle has-text-weight-bold is-size-3 mt-5">Supplementary information</p>
<div class="columns">
    <div class="column" *ngFor="let sub of ['']; let i = index">
        <div class="card">
            <header class="card-header">
                <p class="card-header-title">{{ sub }}</p>
            </header>
            <div class="card-content">
                <img [src]="">
            </div>       
        </div>
    </div>
</div>

