<section class="hero is-large is-info">
    <div class="mx-6 my-6">
        <div class="container has-text-centered">
            <p class="title is-size-1">
                Smart Ulsan Platform
            </p>
            <p class="subtitle is-size-3">
                Urban and Environment
            </p>
        </div>
    </div>
</section>
<section>
    <div class="title has-text-centered mx-5 mt-6 pt-6">
        <span>Recent Research Performance</span>
    </div>
    <div class="columns is-mobile is-multiline is-vcentered is-centered my-6">
        <div class="column is-6-mobile is-4-tablet is-3-desktop is-2-fullhd" *ngFor="let gallery of data">
            <div class="card">
                <div class="card-image is-clickable thumbnail-wrappper mx-2 my-1" (click)="open(gallery)">
                    <div class="thumbnail">
                        <div class="centered">
                            <img [src]="filesToImageSources(gallery)[0]">
                        </div>
                    </div>
                    <span class="image-count is-size-7 has-text-weight-semibold" *ngIf="hasImages(gallery)">+{{ imageCount(gallery) }}</span>
                </div>
                <div class="card-content">
                    <div class="mb-2">
                        <p class="title is-5">{{ gallery.title }}</p>
                    </div>
    
                    <div class="content">
                        <div>
                            <i class="far fa-eye fa-xs mr-1"></i>
                            <span class="is-size-7">{{ gallery.viewCount }}</span>
                        </div>
                        <div>
                            <i class="far fa-clock fa-xs mr-1"></i>
                            <span class="is-size-7">{{ gallery.updatedAt | date: 'yyyy-MM-dd HH:mm:ss' }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>