import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MeteogramComponent } from './meteogram.component';
import { TableModule } from 'src/app/common/table/table.module';
import { DefaultImageModule } from 'src/app/common/default-image/default-image.module';



@NgModule({
  declarations: [
    MeteogramComponent
  ],
  imports: [
    CommonModule,
    DefaultImageModule
  ],
  exports: [
    MeteogramComponent
  ]
})
export class MeteogramModule { }
