import { Injectable } from '@angular/core';
import { FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AuthService } from 'src/app/http/auth.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  constructor(private auth : AuthService) {}

  isValid(form: FormGroup, formControlName: string): boolean {
    return form.controls[formControlName].valid
  }

  isShowHelper(form: FormGroup, formControlName: string): boolean {
    const control = form.controls[formControlName];
    return control.value.length > 0 && !control.valid;
  }

  classValue(form: FormGroup, formControlName: string): string {
    return this.isValid(form, formControlName) ? "is-success" : "is-danger";
  }

  emailValidator(): ValidatorFn {
    return Validators.pattern(/^(\w+\.)*\w+@(\w+\.)+[A-Za-z]{2,}$/i);
  }

  emailAvaliable(controlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];

      if(control.errors){
        return;
      } 

      if(control.value != null) {        
        this.auth.validEmail(control.value).subscribe(res => {  
          if(res != null && res.hasOwnProperty('email')) control.setErrors({notUnique: true})    
        }, (error: HttpErrorResponse) => {
          console.log(error);    
        }); 
      }
    }    
  }

  passwordValidator(): ValidatorFn {
    return Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@#!%?&])[A-Za-z\d$@#!%?&]{8,}$/i);
  }

  match(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }
}
