import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as dateFnsFp from "date-fns/fp";
import * as _ from 'lodash';

@Component({
  selector: 'app-date-controls',
  templateUrl: './date-controls.component.html',
  styleUrls: ['./date-controls.component.css']
})
export class DateControlsComponent implements OnInit {
  @Input()
  date = new Date();

  @Output()
  dateChange = new EventEmitter<Date>();

  @Output()
  ready = new EventEmitter<Date>();

  ngOnInit(): void {
    this.ready.emit(this.date);
  }

  timeChangeAmounts(reverse?: boolean) {
    const vs = ["1D", "2D", "7D"];
    return reverse ? vs.map(v => "-" + v).reverse() : vs.map(v => "+" + v);
  }

  changeDate(data: { start: Date }) {
    if (this.date == data.start)
      return;

    this.date = data.start;
    this.dateChange.emit(this.date);
  }

  changeDateByButton(value: string) {
    const amount = parseInt(value.substr(0, 2), 10);
    const unit = value.substr(2, 1).toLowerCase();
    this.date = dateFnsFp.addDays(amount)(this.date);
    this.dateChange.emit(this.date);
  }

  isDisableMoveToFuture() {
    return _.flow(
      dateFnsFp.set({
        hours: 0,
        minutes: 0,
        seconds: 0
      }),
      dateFnsFp.isBefore(this.date)
    )(new Date());
  }
}
