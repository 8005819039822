<ng-container *ngIf="isValid(); else elseEmailTemplate">
    <span class="icon is-small is-right">
        <i class="fa fa-check"></i>
    </span>
</ng-container>
<ng-template #elseEmailTemplate>
    <span class="icon is-small is-right">
        <i class="fa fa-exclamation-triangle"></i>
    </span>
</ng-template>
