import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GalleryViewerComponent } from './main/section/content-with-sidebar/city/gallery/viewer/viewer.component';
import { FileType } from './common/rich-text/file-type.enum';
import { EnhancedRichTextEditorComponent } from './common/rich-text/rich-text-editor/enhanced-rich-text-editor/enhanced-rich-text-editor.component';
import { EnhancedRichTextViewerComponent } from './common/rich-text/rich-text-viewer/enhanced-rich-text-viewer/enhanced-rich-text-viewer.component';
import { AuthGuard } from './http/auth.guard';
import { MainComponent } from './main/main.component';
import { ProfileComponent } from './main/section/auth/profile/profile.component';
import { SignInComponent } from './main/section/auth/sign-in/sign-in.component';
import { SignUpComponent } from './main/section/auth/sign-up/sign-up.component';
import { AmbientAirConcentrationsComponent } from './main/section/content-with-sidebar/atmospheric-environment/ambient-air-concentrations/ambient-air-concentrations.component';
import { AtmosphericEnvironmentComponent } from './main/section/content-with-sidebar/atmospheric-environment/atmospheric-environment.component';
import { CmaqComponent } from './main/section/content-with-sidebar/atmospheric-environment/cmaq/cmaq.component';
import { KalionKoreaComponent } from './main/section/content-with-sidebar/atmospheric-environment/kalion-korea/kalion-korea.component';
import { PandoraUnistComponent } from './main/section/content-with-sidebar/atmospheric-environment/pandora-unist/pandora-unist.component';
import { CityComponent } from './main/section/content-with-sidebar/city/city.component';
import { ContentWithSidebarComponent } from './main/section/content-with-sidebar/content-with-sidebar.component';
import { AnnouncementsComponent } from './main/section/content-with-sidebar/news/announcements/announcements.component';
import { CurrentComponent as CurrentWeatherAnalysisComponent } from './main/section/content-with-sidebar/weather-analysis/current/current.component';
import { ForecastComponent as ForecastWeatherAnalysisComponent } from './main/section/content-with-sidebar/weather-analysis/forecast/forecast.component';
import { WeatherAnalysisComponent } from './main/section/content-with-sidebar/weather-analysis/weather-analysis.component';
import { HomeComponent } from './main/section/home/home.component';
import { GalleryComponent } from './main/section/content-with-sidebar/city/gallery/gallery.component';
import { AuthResolver } from './http/auth.resolver';
import { DataReportsComponent } from './main/section/content-with-sidebar/city/data-reports/data-reports.component';

function _boardRoutes(
  listComponent: any,
  editorOption?: {
    storeKey?: string,
    fileType?: FileType
  }) {

    editorOption = Object.assign({
      hiddenSubMenu: true
    }, editorOption);

  return [
    { path: '', component: listComponent, data: editorOption },
    { path: 'view/:id', component: EnhancedRichTextViewerComponent, data: editorOption },
    { path: 'new', component: EnhancedRichTextEditorComponent, data: editorOption},
    { path: 'edit/:id', component: EnhancedRichTextEditorComponent, data: editorOption, resolve:{data: AuthResolver} },
    { path: '**', redirectTo: '', pathMatch: 'full' }
  ];
}


const routes: Routes = [
  {
    path: '', component: MainComponent,
    children: [
      { path: '', component: HomeComponent, data: {storeKey: 'gallery'} },
      { path: 'signin', component: SignInComponent, canActivate: [AuthGuard] },
      { path: 'signup', component: SignUpComponent, canActivate: [AuthGuard] },
      { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
      { path: 'announcements', component: AnnouncementsComponent },
      {
        path: '', component: ContentWithSidebarComponent,
        children: [
          {
            path: 'weather',
            component: WeatherAnalysisComponent,
            data: {
              title: '기상 분석'
            },
            children: [
              { path: 'current', component: CurrentWeatherAnalysisComponent, data: { title: '현재' } },
              { path: 'forecast', component: ForecastWeatherAnalysisComponent, data: { title: '예측' } },
              { path: '**', redirectTo: 'current', pathMatch: 'full' }
            ]
          },
          {
            path: 'atmospheric_environment',
            component: AtmosphericEnvironmentComponent,
            data: {
              title: '대기 환경'
            },
            children: [
              {
                path: 'current',
                data: {
                  title: '현재'
                },
                children: [
                  {
                    path: 'ambient_air_concentrations',
                    component: AmbientAirConcentrationsComponent,
                    data: {
                      title: '현재 미세먼지 관측 (전국 분포)'
                    }
                  },
                  {
                    path: 'kalion-korea',
                    component: KalionKoreaComponent,
                    data: {
                      title: 'UNIST 라이다 관측(미세먼지)'
                    }
                  },
                  {
                    path: 'pandora-unist',
                    component: PandoraUnistComponent,
                    data: {
                      title: 'UNIST 판도라 관측(가스 상)'
                    }
                  },
                  { path: '**', redirectTo: 'ambient_air_concentrations', pathMatch: 'full' }    
                ]
              },
              { path: 'forecast', component: CmaqComponent, data: { title: '예측' } },
              { path: '**', redirectTo: 'current', pathMatch: 'full' }
            ]
          },
          {
            path: 'city', component: CityComponent,
            data: {
              title: '도시 방재'
            },
            children: [
              {
                path: 'data_reports',
                data: {
                  title: 'Data & Reports'
                },
                children: _boardRoutes(DataReportsComponent) 
              },
              { 
                path: 'gallery',
                data: {
                  title: 'Research Performance'
                },
                children: [
                  { path: '', component: GalleryComponent},
                  { path: 'view/:id', component: GalleryViewerComponent },
                  { path: 'new', component: EnhancedRichTextEditorComponent, data: { hiddenSubMenu: true, fileType: FileType.IMAGE  } },
                  { path: 'edit/:id', component: EnhancedRichTextEditorComponent, data: { fileType: FileType.IMAGE }, resolve:{data: AuthResolver} },
                  { path: '**', redirectTo: 'gallery', pathMatch: 'full' }    
                ]            
              },
              { path: '**', redirectTo: 'data_reports', pathMatch: 'full' }    
            ]
          },
          // {
          //   path: 'disaster_protection',
          //   component: DisasterProtectionComponent,
          //   data: {
          //     hiddenSubMenu: true,
          //     title: '방재'
          //   }
          // },
          /* {
            path: 'field_campaign_data_collected',
            canActivate: [AuthGuard],
            children: [
              {
                path: 'aircraft',
                children: _boardRoutes(AircraftMeasurementsComponent)
              },
              {
                path: 'ground_supersite',
                children: _boardRoutes(GroundSupersiteComponent)
              },
              {
                path: 'gcas',
                children: _boardRoutes(GcasMeasurementsComponent)
              },
              {
                path: 'max-doas',
                children: _boardRoutes(MaxDoasComponent, { type: boardOption.maxdoas })
              },
              {
                path: 'car-doas',
                children: _boardRoutes(CarMobileDoasComponent, { type: boardOption.doas })
              },
              {
                path: 'pandora',
                children: _boardRoutes(PandoraListComponent, { type: boardOption.pandora })
              },
              {
                path: 'micro_meteorology',
                children: _boardRoutes(MicroMeteorologicalAnalysisComponent)
              },
              {
                path: 'satellite_rgb',
                children: _boardRoutes(SatelliteRgbComponent),
                data: { title: "Satellite - GK2A AMI RGB + GCAS path" }
              },
              {
                path: 'information_exchange',
                children: _boardRoutes(InformationExchangeComponent)
              },
              { path: '**', redirectTo: 'aircraft', pathMatch: 'full' }
            ]
          }, */
          /* {
            path: 'admin',
            canActivate: [AuthGuard],
            children: [
              {
                path: 'members', component: MemberManagementComponent,
                children: [
                  {
                    path: 'list', component: MemberListComponent,
                    children: [
                      { path: 'permitted', component: PermittedMembersComponent },
                      { path: 'waiting', component: WaitingMembersComponent },
                      { path: '**', redirectTo: 'permitted', pathMatch: 'full' }
                    ]
                  },
                  { path: 'edit', component: MemberFormComponent },
                  { path: '**', redirectTo: 'list', pathMatch: 'full' }
                ]
              },
              { path: '**', redirectTo: 'members', pathMatch: 'full' }
            ]
          }, */
          { path: '**', redirectTo: '', pathMatch: 'full' }
        ]
      },
      { path: '**', redirectTo: '', pathMatch: 'full' }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
