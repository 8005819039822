<app-time-range-slider [unit]="'h'" [intervals]="[3]" [interval]="3" [step2]="6" (ready)="getReady($event)"
  (dateChange)="getDateChange($event)" (valueChange)="getValueChange($event)" [relativeMin]="24" [relativeMax]="75">
</app-time-range-slider>

<div class="columns is-vcentered is-mobile my-3">

  <div class="column">
    <div class="radio-field-container">
      <app-radio-field [values]="['PM25', 'CO', 'NH3', 'O3', 'NO2', 'SO2']"
        [subLabels]="['PM2.5', 'CO', 'NH3', 'O3', 'NO2', 'SO2']" [label]="'variables'" [(form)]="form"
        [controlName]="'variable'" class=""></app-radio-field>
    </div>
  </div>
</div>

<div class="columns is-multiline">
  <div class="column is-4" *ngFor="let domain of domainSchema">
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          {{ domain.name?? domain.value }}
        </p>
      </header>

      <div class="card-content has-text-centered">
        <img [src]="getImageSource(domain.value)">
      </div>
    </div>
  </div>
</div>

<div class="card">
  <header class="card-header">
    <p class="card-header-title">
      27km
    </p>
  </header>

  <div class="card-content has-text-centered">
    <img [src]="getImageSource('27km')">
  </div>
</div>