import { Component } from '@angular/core';
import * as dateFns from "date-fns";

@Component({
  selector: 'app-current',
  templateUrl: './current.component.html',
  styleUrls: ['./current.component.css']
})
export class CurrentComponent {
  date: Date = dateFns.startOfDay(new Date());

  onChangedDate(date: Date) {
    this.date = dateFns.startOfDay(date);
  }
}
