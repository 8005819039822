import { Component, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from "lodash";
import { map } from 'rxjs/operators';
import { File } from 'src/app/store/schema';
import { environment } from 'src/environments/environment';
import { Control } from '../../select/select.component';
import { SelectService } from '../../select/select.service';
import { AbstractEditor } from '../abstract-editor';
import { RichText } from '../domain';
import { FileType } from '../file-type.enum';
import { Mode } from '../mode.enum';

const trimValidator: ValidatorFn = (control: FormControl) => {
  if (control.value.replace(/&nbsp;/gi, "").trim().length <= 0) {
    return {
      'trimError': { value: 'control has trailing whitespace' }
    }
  }

  return null;
};

@Component({
  selector: 'app-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.css']
})
export class RichTextEditorComponent extends AbstractEditor implements OnInit, OnChanges {
  // FIXME env
  /* readonly limit = 10;
  readonly maxSize = Math.pow(1024, 3); */
  maxFileSize: number = 0;
  maxRequestSize: number = 0;

  private isDrag: boolean;

  @ViewChildren('imgs')
  imgs: QueryList<any>;

  @Input()
  uploadImagePath: string;

  @Input()
  mode: Mode;

  @Input()
  fileType: FileType = FileType.ALL;

  @Input()
  uploadedFiles: File[]

  controlGroup: Control[][];

  @Output()
  uploadedFilesChange = new EventEmitter<File[]>();

  @Output()
  save = new EventEmitter<RichText>()

  fileList: any[] = [];

  get config() {
    const ButtonsAtInsert = ['link', 'video', 'hr', 'table'];
    if (this.uploadImagePath?.length > 0) {
      return ButtonsAtInsert.unshift('picture');
    }

    const config = {
      placeholder: '',
      tabsize: 2,
      height: '500px',
      toolbar: [
        ['misc', ['undo', 'redo']],
        ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
        ['fontsize', ['fontsize', 'color']],
        ['para', ['style', 'ul', 'ol', 'paragraph']],
        ['insert', ButtonsAtInsert]
      ]
    }

    if (this.uploadImagePath?.length > 0) {
      config['uploadImagePath'] = this.uploadImagePath;
    }

    return config;
  }

  editorForm: FormGroup;

  constructor(protected route: ActivatedRoute, protected router: Router, protected fb: FormBuilder, public select: SelectService) {
    super(route, router);
  }

  ngOnInit(): void {
    super.ngOnInit();

    const data = this.route.snapshot.data;
    this.controlGroup = this.select.typeToControls(data['type'], false);

    this.maxFileSize = this.sizeToBytes(environment.maxFileSize);
    this.maxRequestSize = this.sizeToBytes(environment.maxRequestSize);
    if (!this.maxFileSize || !this.maxRequestSize) {
      alert("The file size setting to upload is invalid.");
    }

    this.editorForm = this.fb.group({
      title: [this.title || '', [Validators.required, trimValidator]],
      content: [this.content || '', [Validators.required, trimValidator]],
      // type: this.select.decode(this.type)
    });

    const type: string[][] = this.route.snapshot.data['type'];
    const typeIndicies = type?.map(_ => 1);
    
    this.editorForm.addControl('type', new FormControl(typeIndicies));

    this.editorForm.valueChanges.pipe(
      map((value) => {
        value.title = value.title.trim();

        if (value.content.replace(/&nbsp;/gi, "").trim().length <= 0) {
          value.content = "";
        }
        return value;
      })
    ).subscribe();
  }

  ngAfterViewChecked(): void {
    if (this.imgs == null || this.imgs.length <= 0)
      return;

    this.imgs.map(imgRef => imgRef.nativeElement).forEach((img: HTMLImageElement) => {
      const index = parseInt(img.dataset.index, 10);
      this.showThumnbail(img, this.fileList[index]);
    });

    this.imgs = null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.title != null) {
      this.editorForm.patchValue({ title: this.title });
    }

    if (this.content != null) {
      this.editorForm.patchValue({ content: this.content });
    }

    if (this.type != null) {
      const typeIndicies = this.select.decode(this.type);

      const tempControls = _.cloneDeep(this.controlGroup);
      typeIndicies.forEach((selectedValue, selectControlIndex) => {
        tempControls[selectControlIndex].forEach((control, controlIndex) => {
          control.checked = controlIndex == selectedValue - 1;
        })
      });

      this.controlGroup = tempControls;

      this.editorForm.patchValue({
        type: typeIndicies
      });
    }
  }

  get isValid() {
    return this.editorForm.valid;
  }

  trimValues() {
    const controls = this.editorForm.controls;

    this.editorForm.setValue(
      {
        title: controls.title.value.trim(),
        content: controls.content.value.trim(),
        type: controls.type.value
      }
    )
    return this.editorForm.controls.title.value.trim();
  }

  get saveButtonColorClass() {
    return this.mode == Mode.EDIT ? "is-success" : "is-info";
  }

  get accept() {
    return FileType.toExtensionCSV(this.fileType);
  }

  get hasAttachment() {
    return this.fileType != FileType.NONE;
  }

  private sizeToBytes(size: string): number {
    const units = ["bytes", "kb", "mb", "gb", "tb"];

    const sizeAndUnit: string[] = size.match(/([0-9]+)(bytes|kb|mb|gb|tb)/i)?.slice(1, 3);
    if (!sizeAndUnit)
      return null;

    const pow = units.findIndex((value) => value == sizeAndUnit[1].toLowerCase());
    const unitToSize = Math.pow(1024, pow);
    return parseInt(sizeAndUnit[0], 10) * unitToSize;
  }

  handleFiles(fileList: FileList) {
    // check type and length
    const fileList1 = Array.from(fileList)
      .filter(file => file.size < this.maxFileSize)
      .filter(file => {
        if (this.fileType == FileType.ALL)
          return true;

        return this.fileType == FileType.fileNameToFileType(file.name);
      })
      .filter(file => {
        // 새 파일 추가시, 중복 파일명 배제
        if (this.fileList?.length <= 0)
          return true;

        return this.fileList.find(f => f.name == file.name) == null;
      })
      .filter(file => {
        // edit일 때, 중복 파일명 배제
        if (this.uploadedFiles == null || this.uploadedFiles?.length <= 0)
          return true;

        return this.uploadedFiles.find(uf => uf.name == file.name) == null;
      });

    const fileList2 = this.fileList.concat(fileList1)
      .sort((f1, f2) => f1.size - f2.size);

    // check size
    let totalSize;
    const reducedFileList = [];
    for (let i = 0; i < fileList2.length; i++) {
      const file = fileList2[i];
      totalSize += file.size;

      if (totalSize > this.maxRequestSize)
        break;

      reducedFileList.push(file);
    }

    this.fileList = reducedFileList
      .sort((f1, f2) => f1.name.localeCompare(f2.name))
      .sort((f1, f2) => FileType.fileNameToFileType(f1.name) - FileType.fileNameToFileType(f2.name));
  }

  preventDrag(e) {
    e.stopPropagation();
    e.preventDefault();

    this.isDrag = true;
  }

  dropFiles(e) {
    e.stopPropagation();
    e.preventDefault();

    this.isDrag = false;

    const dt = e.dataTransfer;
    const files = dt.files;

    this.handleFiles(files);
  }

  get uploadActionMessage(): string {
    return this.isDrag ? "Drop files here" : "Choose files or drag it here";
  }

  get uploadLimitMessage(): string {
    return ["(Max file size : ", environment.maxFileSize, ", Total file size : ", environment.maxRequestSize, ")"].join(" ");
  }

  get fileTypeMessage(): string {
    return this.fileType == FileType.ALL ? "" :
      FileType.toString(this.fileType) + " only";
  }

  get totalFileSize() {
    return this.fileList?.map(f => f.size).reduce((p, r) => {
      p += r;
      return p;
    }, 0) + this.totalUploadedFileSize;
  }

  get totalUploadedFileSize() {
    let totalUploadedFilesSize = 0;

    if (this.uploadedFiles != null && this.uploadedFiles.length > 0) {
      totalUploadedFilesSize = this.uploadedFiles.map(f => f.size).reduce((p, r) => {
        p += r;
        return p;
      }, 0);
    }

    return totalUploadedFilesSize;
  }

  isImage(file: globalThis.File): boolean {
    return file.type.startsWith('image/');
  }

  private showThumnbail(img: HTMLImageElement, file: globalThis.File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      img.src = reader.result as string;
    };
  }

  deleteFile(index: number) {
    this.fileList.splice(index, 1);
  }

  clear() {
    this.fileList = [];
  }

  deleteUploadedFile(index: number) {
    // 임시 삭제이기때문에 원본 데이터가 변경되면 안됨.
    const uploadedFilesCopy = JSON.parse(JSON.stringify(this.uploadedFiles));
    uploadedFilesCopy.splice(index, 1);
    this.uploadedFiles = uploadedFilesCopy;
    this.uploadedFilesChange.emit(this.uploadedFiles);
  }

  savePost() {
    this.trimValues();
    const value = this.editorForm.value;

    const types = this.editorForm.controls.type.value;
    value['type'] = this.select.encode(types);
    value['files'] = this.fileList;
    this.save.emit(value);
  }

  changeSelect(selectControlIndex: number, valueIndex: number) {
    let temp = this.editorForm.get("type").value;
    temp[selectControlIndex] = valueIndex + 1;

    this.editorForm.patchValue({
      type: temp
    });
  }

}
