import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import * as dateFnsFp from 'date-fns/fp';
import * as _ from "lodash";
import { forkJoin } from 'rxjs';
import { ProgressService } from 'src/app/common/progress/progress.service';
import { OpenApiService } from 'src/app/http/open-api.service';

@Component({
  selector: 'app-satellite',
  templateUrl: './satellite.component.html',
})
export class SatelliteComponent implements OnInit, OnChanges, OnDestroy {

  serviceKey = "4%2BnxKCEC1Y9rJHeg4%2BDmfM3cRHuVWNBJPk0Oob4C%2BeFCCvkTxlSRR3ZGNEGspHhb0DVg%2B9UBPg30%2FS9L%2FEJBKA%3D%3D";
  imageArr: string[] = [];
  imageUrl: string;
  index: number = 0;
  header = "Satellite (GEO-KOMPSAT-2A)";

  timerId: any = null;

  delayThreshold = 5;

  @Input()
  date = new Date();

  constructor(private api: OpenApiService, private progress: ProgressService) { }

  ngOnInit(): void {
    // this.update();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes?.date.isFirstChange()) {
      this.update();
    }
  }

  update() {
    this.imageArr = [];

    this.progress.show("waitingApiResponse", {
      errorLimitSec: 10
    });

    const apiUrl = "http://apis.data.go.kr/1360000/SatlitImgInfoService/getInsightSatlit?serviceKey=" + this.serviceKey + "&pageNo=1&numOfRows=10&dataType=JSON&sat=G2&data=rgbt&area=ko&time=";
    let formatter = dateFnsFp.format("yyyyMMdd");

    // const today = new Date();
    const observable1 = this.api.list(apiUrl + formatter(this.date));

    if (this.date.getHours() > 12) {
      observable1.subscribe(response => {
        this.imageArr = this.getImageUrl(response as any);
      },
        _error => {
          this.imageArr = [];
          this.progress.hide();
        },
        () => {
          this.updateInterval();
          this.progress.hide();
        });
    }
    else {
      const observable2 = this.api.list(apiUrl + _.flow(
        dateFnsFp.subDays(1),
        formatter
      )(this.date));

      forkJoin([observable2, observable1]).subscribe((obsArr: any[]) => {
        const arr = obsArr.reduce((p, r) => p.concat(r), []);
        this.imageArr = this.getImageUrl(arr);
      },
        _error => {
          this.imageArr = [];
          this.progress.hide();
        },
        () => {
          this.updateInterval();
          this.progress.hide();
        });
    }
  }

  getImageUrl(imageArray: string[]) {
    if (imageArray.length <= 0)
      return [];

    const temp = [];

    for (let i = imageArray.length - 1; i >= 0; i -= 5) {
      if (temp.length >= 18)
        break;

      temp.unshift(imageArray[i].replace("http", "https"));
    }

    return temp;
  }

  updateInterval() {
    clearInterval(this.timerId);
    this.index = 0;

    let temp = 0;

    this.timerId = setInterval(() => {
      this.imageUrl = this.imageArr[this.index];
      this.index = ++this.index % this.imageArr.length;

      const dataLength = this.imageArr.length - 1;
      if (this.index >= dataLength || temp > 0) {
        this.index = dataLength;
        temp++;

        if (temp >= this.delayThreshold) {
          this.index = 0;
          temp = 0;
        }
      }
    }, 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.timerId);
  }
}
