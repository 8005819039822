<app-time-range-slider [currentDate]="date" [unit]="'h'" [intervals]="[1]" [interval]="1" [step2]="3"
    (valueChange)="getValueChange($event)" (dateChange)="getDateChange($event)" (ready)="getReady($event)">
</app-time-range-slider>

<div class="card">
    <header class="card-header">
        <p class="card-header-title">
            {{ title }}
        </p>
    </header>

    <div class="card-content has-text-centered">
        <img [src]="getImageSource()">
    </div>
</div>