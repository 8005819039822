<div class="columns is-multiline" *ngFor="let i of getSubColunmsIndices()">
    <div class="column" [ngClass]="getColumnStyle(i)" *ngFor="let col of getSubColumns(i)">
        <div class="card">
            <header class="card-header">
                <p class="card-header-title is-size-4">
                    {{ col.title }}
                </p>
            </header>
            <div class="card-image has-text-centered">
                <figure class="mx-3 my-3">
                    <img [src]="col.src" />
                </figure>
            </div>
        </div>
    </div>
</div>