import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxBulmaPaginationComponent, NgxBulmaPaginationModule } from 'ngx-bulma-pagination';
import { DefaultImageModule } from '../default-image/default-image.module';
import { BasicTableComponent } from './basic-table/basic-table.component';
import { CollapsibleTableComponent } from './collapsible-table/collapsible-table.component';

@NgModule({
  declarations: [BasicTableComponent, CollapsibleTableComponent],
  imports: [
    CommonModule,
    NgxBulmaPaginationModule,
    DefaultImageModule
  ],
  exports: [NgxBulmaPaginationComponent, BasicTableComponent, CollapsibleTableComponent]
})
export class TableModule { }
